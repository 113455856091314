@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 640px;
  background-color: $link-water-gray;
  transform: translateX(120%);
  transition: 500ms;
  z-index: 100;
  box-shadow: $btn-box-shadow;

  &--md {
    width: 900px;
  }

  &--wide {
    width: 100vw;
    max-width: 1280px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: 200ms;
    box-sizing: border-box;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10%;
      width: 80%;
      height: 2px;
      background-color: $body-color;
      transform: rotate(45deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 10%;
      width: 80%;
      height: 2px;
      background-color: $body-color;
      transform: rotate(-45deg);
    }
  }

  &--active {
    transform: translateX(0);
  }
}
