@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
$toolbar-height: 90px;
$offset-bottom: 235px;

:global {
  .geofences {
    margin-top: 35px;
    background-color: white;
    border-radius: 5px;
    height: calc(100vh - #{$offset-bottom});
    min-height: 700px;
    box-shadow: 0 2px 5px rgba(0,0,0, 0.1);
    $self: &;

    &__inner {
      height: calc(100% - #{$toolbar-height});
    }

    &--full-screen {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      margin-top: 0;
      border-radius: 0;
      height: 100%;

      .geofences-map {
        width: 100%;
      }

      #{ $self }__actions {
        .btn {
          height: 40px;
          padding: 0 15px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}