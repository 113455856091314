@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .upload-image-wrapper {
    &--rounded {
      .upload-image {
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 135px;
        height: 135px;

        &__thumb {
          border-radius: 100%;
        }

        &__btns {
          left: -5px;
        }

        .upload-image__dnd-overlay {
          border-radius: 100%;
        }
      }
    }
  }

  .upload-image {
    $self: &;

    border: 1px dashed $bali-hai-blue;
    border-radius: 3px;
    position: relative;

    &__dnd-overlay {
      position: absolute;
      top: 0;
      overflow: hidden;
      transition: opacity 0.3s;
      left: -99999px;
      background-color: #F9FBFF !important;
      width: 100%;
      height: 100%;
      display: flex;
      opacity: 0;
      justify-content: center;
      align-items: center;
      z-index: 999;
      pointer-events: none;
    }

    &__form {
      height: 225px;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;

      &--dragover {
        position: relative;

        #{$self}__dnd-overlay {
          left:0;
          opacity: 1;
        }
      }

      &--small {
        padding: 0;
        height: 120px;
        width: 120px;
      }
    }

    &__placeholder {
      display: inline-block;
      width: 48px;
      height: 38px;

      path {
        transition: 200ms;
      }
    }

    &__input {
      position: absolute;
      inset: 0;
      width: 100%;
      display: block;
      cursor: pointer;
      opacity: 0;
    }

    &__text {
      color: $blue-gray;
      transition: 200ms;
    }

    &__thumb-wrapper {
      min-width: 120px;
    }

    &__thumb {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__error {
      bottom: 10%;
      text-align: center;
      pointer-events: none;

      &--small {
        padding: 15px 0;
        bottom: auto;
        left: 0;
        transform: none;
        background-color: rgba(#fff, 0.9);
        pointer-events: none;
      }
    }

    &__btns {
      position: absolute;
      left: 0;
      z-index: 2;
    }

    &__form {
      &:hover {
        #{$self}__text {
          color: $pulsate-blue;
        }

        #{$self}__placeholder path {
          fill: $pulsate-blue;
        }
      }
    }

    &--rich-media {
      border: none;

      #{$self}__form {
        height: auto;
        padding-top: 2em;
        padding-bottom: 2em;
      }
    }
  }
}
