@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.step-media-wrapper {
  margin-right: -20px;
}

.step-media {
  width: 390px;

  @media only screen and (min-width: 1550px) {
    width: 590px;
  }
}
