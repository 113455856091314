.dndnode {
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 14px;
  border: 2px solid #1a192b;
  border-radius: 4px;
  cursor: grab;
  transition: 200ms;

  &--action {
    background-color: #ccc;

    &:hover {
      background-color: darken(#ccc, 10);
    }
  }

  &--message {
    background-color: #eee;
    &:hover {
      background-color: lighten(#eee, 10);
    }
  }
}

.custom-handle {
  opacity: 0;
}

.custom-edge {
  stroke: $blue-gray !important;
  stroke-dasharray: 3;
}

.react-flow__edge--redirect .custom-edge {
  stroke: $fuchsia-pink !important;
}

$iconsHolderHeight: 90px;

.flow-node {
  $self: &;
  z-index: -1;
  position: relative;

  &__icon-wrapper {
    margin: 0 auto;
    width: 90px;
    height: 66px;
    background-color: $link-water-gray;
    box-shadow: 0 4px 15px 0 #28518c4d inset;
    border: 5px solid white;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
  }

  &__wrapper {
    padding: 30px 15px 10px;
    width: 360px;
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-gray;
    border-radius: 3px;
    border: 1px solid transparent;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    margin-top: -$iconsHolderHeight / 3;
  }

  &__hover {
    transition: 200ms;
    &:hover {
      box-shadow: 0px 4px 10px 0px #28518c80;
    }
  }

  &--redirect &__hover {
    border-radius: 40px;
  }

  &--message &__hover {
    border-radius: 25px;
  }

  &__label {
    margin: 0;
    max-width: 100%;
    color: white;
  }

  &--entry {
    &:not(.flow-node--filled-fuchsia) #{$self}__icon-wrapper {
      background-color: $blue-gray;
      box-shadow: none;
      border: none;
      color: #fff;
    }

    #{$self}__wrapper {
      background-color: transparent;
      min-height: 180px;
      border: 2px dashed $blue-gray;
    }

    #{$self}__label {
      color: $body-color;
    }
  }

  &--active {
    #{$self}__wrapper {
      background-color: #fff;
    }
  }

  &--filled {
    #{$self}__label {
      color: $body-color;
    }

    #{$self}__wrapper {
      background-color: white;
    }
  }

  &--filled-fuchsia {
    #{$self}__label {
      color: white;
    }

    #{$self}__wrapper {
      background-color: $fuchsia-pink;
      border-color: $fuchsia-pink;
    }
  }

  &--error {
    #{$self}__wrapper {
      border-color: #dc3545;
    }
  }

  &--message {
    #{$self}__wrapper {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }

    #{$self}__left-target {
      transform: translate(85px);
    }

    #{$self}__right-target {
      transform: translate(-80px);
    }
  }

  &--exit {
    font-size: 19px;
    text-transform: uppercase;
    color: $blue-gray;
    font-weight: 500;
    width: 37px;
    height: 20px;
    position: relative;
    right: 3px;
  }
}
.react-flow__node.selected .flow-node__hover {
  box-shadow: 0px 4px 10px 0px #28518c80;
}

// .react-flow__node.selected .flow-node--message .flow-node__wrapper {
//   box-shadow: none;
// }
