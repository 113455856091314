.footer {
  padding: $spacer ($spacer / 2);

  @include media-breakpoint-up(lg) {
    padding: $spacer ($spacer * 1.5);
  }

  ul {
    margin-bottom: 0;
  }
}
