.apexcharts-canvas {
  .apexcharts-svg {
    overflow: visible;

    &.apexcharts-zoomable {
      &.hovering-pan {
        cursor: grab;

        &:active {
          cursor:grabbing;
        }
      }
    }
  }

  .apexcharts-xaxis-label {
    fill: $blue-gray;
  }

  .apexcharts-yaxis-label {
    fill: $blue-gray;
  }

  .apexcharts-tooltip {
    &.apexcharts-theme-shadow {
      box-shadow: 0 4px 4px rgba(40, 81,140, 0.5);
    }
  }
}
