@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  $targetingBoxSpacing: 18;
  $bubbleHeight: 40;

  .bubble-wrapper {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 36px;
      left: 50%;
      border-left: 1px dashed $pigeon-post;
      z-index: 0;
    }
  }

  .bubble {
    position: relative;
    margin: #{$targetingBoxSpacing}px auto;
    width: 320px;
    height: #{$bubbleHeight}px;
    background-color: $secondary;
    border-radius: 26px;
    font-size: 16px;
    color: white;
    text-align: center;
    line-height: 40px;

    &--mini {
      width: 75px;
    }

    &--outline {
      background-color: $link-water-gray;
      border: 1px solid $secondary;
      color: $secondary;
    }

    @include media-breakpoint-up('xxl') {
      margin: #{$targetingBoxSpacing * 2}px auto;
    }
  }

  .targeting-box-wrapper {
    position: relative;
    margin: #{$targetingBoxSpacing}px auto;
    width: 325px;
    min-height: 85px;

    @include media-breakpoint-up('xxl') {
      margin: #{$targetingBoxSpacing * 2}px auto;
    }
  }

  .targeting-box-collapse {
    margin-top: -85px;
  }

  .targeting-box {
    position: relative;
    padding: 20px;
    width: 100%;
    min-height: 85px;
    margin-left: auto;
    background: white;
    border-radius: 3px;
    border: 1px solid $bali-hai-blue;
    color: $pulsate-blue;
    box-sizing: border-box;
    transition: 300ms;
    bottom: 0;
    opacity: 1;

    &__trigger {
      margin: 0;
      color: $light-pulsate-blue;
      font-size: 14px;
      line-height: 21px;
    }

    &__name {
      font-size: 16px;
      line-height: 24px;
      word-break: break-word;
    }

    &__icon {
      max-height: 30px;
      max-width: 35px;
    }

    &--grouped {
      position: relative;
      z-index: 10;
      opacity: 0;

      &:before,
      &:after {
        content: '';
        position: absolute;
        background: white;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border: 1px solid $bali-hai-blue;
        background-color: $link-water-gray;
        color: $pulsate-blue;
        box-sizing: border-box;
      }

      &:before {
        left: 5%;
        right: 5%;
        bottom: -5px;
        height: 5px;
        z-index: 1;
        @include media-breakpoint-up('xxl') {
          height: 10px;
          bottom: -10px;
        }
      }
      &:after {
        left: 10%;
        right: 10%;
        bottom: -10px;
        height: 10px;
        z-index: 0;

        @include media-breakpoint-up('xxl') {
          bottom: -20px;
          height: 20px;
        }
      }
    }

    &--collapsed {
      opacity: 1;
    }
  }

  .dot {
    position: absolute;
    color: $nepal;
    content: '\25CF';
    font-size: 15px;
    line-height: 1em;
    z-index: 1;
  }

  .user-count {
    position: absolute;
    top: calc(50% - 1px);
    left: calc(100% + 88px);
    padding: 35px 5px 16px;
    margin: 0;
    width: 90px;
    min-height: 105px;
    background-color: #fff;
    color: $tertiary;
    transform: translateY(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border: 1px dashed $pigeon-post;
    border-radius: 12px;

    &__icon {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 60px;
      height: 60px;
      background: $link-water-gray url(./UI/Users.svg) center / 60% no-repeat;
      border-radius: 50%;
      border: 1px dashed $nepal;
      transform: translate(-50%, -50%);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -45px;
      width: 45px;
      border-top: 1px dashed $pigeon-post;
    }

    .dot {
      top: 50%;
      left: -52.5px;
      transform: translateY(-42%);
    }

    .txt {
      font-size: 12px;
      color: $dark;
      line-height: 1.5;

      strong {
        font-weight: 600;
      }
    }
  }

  .user-count-marker {
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    background: $pigeon-post;

    .dot {
      top: 50%;
      left: calc(100% + 44px);
      transform: translate(-50%, -50%);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      width: 45px;
      border-top: 1px dashed $pigeon-post;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -45px;
      height: calc(100% + #{$targetingBoxSpacing * 2+$bubbleHeight}px);
      border-left: 1px dashed $pigeon-post;
      transform: translateY(-50%);

      @include media-breakpoint-up('xxl') {
        height: calc(100% + #{$targetingBoxSpacing * 4+$bubbleHeight}px);
      }
    }
  }

  .targeting-box {
    &:first-child .user-count-marker::after,
    &:last-child .user-count-marker::after {
      height: calc(50% + #{$targetingBoxSpacing + $bubbleHeight/2}px);
      @include media-breakpoint-up('xxl') {
        height: calc(50% + #{$targetingBoxSpacing * 2 + $bubbleHeight/2}px);
      }
    }

    &:first-child .user-count-marker::after {
      transform: translateY(0);
    }

    &:last-child .user-count-marker::after {
      transform: translateY(-100%);
    }

    &:only-child .user-count-marker::after,
    &--grouped .user-count-marker::after {
      display: none;
    }
  }

  .illustration {
    margin-top: -105px;
  }
}
