@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.checkbox-divider {
  height: 17px;
  width: 1px;
  background-color: $mystic-gray;
  margin: 0 15px;
}

.radio {
  display: none;
}

.radio-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #93a8c5;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 11px;
    height: 11px;
    background-color: $primary;
    border-radius: 50%;
    transition: 200ms;
    transform: translate(2.5px, -50%);
    opacity: 0;
  }
}

.radio:checked + .radio-container::after {
  opacity: 1;
}
