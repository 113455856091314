.separator-line {
  width: 100%;
  // height: 1px;
  border-bottom: 1px solid $mystic-gray;
  // background: $mystic-gray;

  &--dashed {
    border-bottom-style: dashed;
  }
}
