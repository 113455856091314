.redux-toastr {
  .close-toastr.toastr-control {
    color: white;
  }

  .toastr {
    &.rrt-info {
      background-color: #45b4ff;
    }

    &.rrt-success {
      background-color: #5abf9d;
    }

    &.rrt-error {
      background-color: #ee2650;
    }

    .rrt-progressbar {
      background-color: white !important;
    }

    .rrt-left-container {
      padding-left: 10px;
      display: flex;
      justify-content: center;
    }

    .rrt-left-container .rrt-holder .toastr-icon {
      height: 20px !important;
      width: 20px !important;
      margin-top: 0;
    }

    .rrt-left-container .rrt-holder {
      height: 45px;
      width: 45px;
      position: initial;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 13px;
    }

    .rrt-middle-container {
      margin-top: 13px;
    }
  }
}
