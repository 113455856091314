@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.icon-container {
  min-width: 45px;
  text-align: center;
}

.segment-name {
  &:hover {
    color: $fuchsia-pink;
  }
}