@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .modal {

    .close {
      color: $body-color;
      transition: opacity 0.3s;

      &:not(:disabled):not(.disabled):hover,
      :not(:disabled):not(.disabled):focus {
        opacity: 1;
      }
    }

    &-backdrop--custom.show {
      opacity: 0.7;
    }
  }
}
