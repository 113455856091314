@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.list-header {
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}

:global {
  .target-table {
    td:first-child {
      .react-loading-skeleton--select {
        margin-left: 0px;
      }
    }

    .table__row {
      &--disabled {
        opacity: 0.7;
      }
    }
  }
}