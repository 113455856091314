@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.no-data-overlay {
  z-index: 9999;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
  top: 0;
  border-radius: 23px;
  left: 0;

  @include media-breakpoint-up('xxxl') {
    height: calc(100% - 24px);
  }
}

.no-data-overlay-container {
  display: flex;

  @include media-breakpoint-down('xxxl') {
    padding: 0 60px;
  }
}