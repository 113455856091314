@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .new-journey {
    &__banner-close-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 40px;
      opacity: 0.6;
      cursor: pointer;
      transition: opacity 0.3s;
      background-position: bottom right;

      &:hover {
        opacity: 1;
      }
    }

    &__banner {
      color: white;
      padding: 75px 25px 40px 25px;
      background-image: url(./components/banner-bg.svg);
      background-size: cover;
      text-align: center;
      position: relative;
    }

    &__banner-header {
      font-weight: 300;
      font-size: 36px;
      color: white;
      line-height: 52px;
    }

    &__banner-sub-header {
      font-size: 18px;
    }

    &__tiles-header {
      font-size: 18px;
      color: $bali-hai;
    }

    &__box {
      margin-bottom: 24px;
    }

    &__tile {
      height: 100%;
      align-items: center;
      cursor: pointer;
      padding: 30px 0;
      text-decoration: none;

      &--loan {
        .new-journey__tile-media {
          max-width: 78%;
        }
      }
    }

    &__tile-icon-wrapper {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 68px;
      height: 68px;
      display: flex;
      align-items: center;
      border-radius: 100%;
      background: $link-water-gray;
      justify-content: center;
    }

    &__tile-media {
      max-width: 85%;
    }

    &__tile-title {
      padding-right: 59px;
      color: $body-color !important;
      font-size: 18px !important;
    }

    &__tile-description {
      color: $bali-hai;
    }

    &__tile-title,
    &__tile-description {
      text-align: left;
    }

    @include media-breakpoint-up(xxxl) {
      &__tile {
        padding: 40px 0;
      }

      &__tile-icon-wrapper {
        padding: 10px;
        width: 74px;
        height: 74px;
        top: 20px;
        right: 20px;
      }

      &__tile-title {
        padding-right: 44px;
      }
    }

    @include media-breakpoint-up(hd) {
      &__tile-title {
        padding-right: 0;
      }
    }
  }
}
