@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
