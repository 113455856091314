@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.navbar {
  min-height: 80px;
  background: $pulsate-blue;
}

.headway-badge {
  display: flex;
  align-items: center;
}

.avatar {
  object-fit: cover;
}

.apps-wrapper[aria-expanded='false'] {
  &:after {
    transform: rotate(0deg);
  }
}

.apps-wrapper[aria-expanded='true'] {
  &:after {
    transform: rotate(180deg);
  }
}

.apps-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: initial;

  &::after {
    transition: 300ms;
    border: none;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath fill='white' d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' %3E%3C/path%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
  }

  &:active,
  &:focus,
  &:focus-within {
    background-color: #5990b9 !important;
    box-shadow: none;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    &::after {
      color: white;
    }
  }
}

.icon-wrapper {
  border-radius: 50% !important;

  * {
    font-size: 23px !important;
  }
}

.current-app-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 12px;
}

.current-app-name {
  font-family: Poppins, -apple-system, blinkmacsystemfont, 'Segoe UI',
    'Helvetica Neue', arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.app-name {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-link {
  font-family: Poppins, -apple-system, blinkmacsystemfont, 'Segoe UI',
    'Helvetica Neue', arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #28518c;

  &:hover {
    text-decoration: none;
    color: #28518c;
  }
}

.dropdown-min-width {
  min-width: 230px;
  max-width: 230px;
}

.btn-no-outline:focus {
  outline: none;
}

.unread-count {
  position: absolute;
  top: 2px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: $fuchsia-pink;
  color: #fff;
  font-size: 11px;
  line-height: 10px;
  font-weight: bold;
  border: 2px solid $pulsate-blue;
  transform: translate(50%, -50%);
}

.outline-none {
  outline: none;
}
