@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .upload-button {
    label {
      padding-top: $input-btn-padding-y;
      padding-bottom: $input-btn-padding-y;
      padding-left: $input-btn-padding-x;
      padding-right: $input-btn-padding-x;
    }
  }
}