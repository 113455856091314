@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .steps-box {
    max-width: 1200px;
  }
  .step {
    padding: 0;
    background-color: transparent;
    border: none;
    transition: all 0.3s;
    transform: translateY(0);

    &:hover {
      transform: translateY(-3px);
    }

    &:focus,
    &:active,
    &:hover:not(:disabled):not(.disabled) {
      outline: none;
      box-shadow: none;
      // background-color: $primary;
    }

    &--enabled {
      opacity: 0.4;
    }

    &--active {
      opacity: 1;
      &:hover {
        transform: none;
      }
    }

    &__number-wrapper {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      background-color: $pulsate-blue;
      border: none;
      border-radius: 100%;
      transition: all 0.3s;
      font-weight: 500;
      color: #fff;
    }

    &__number {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      border-radius: 100%;
      padding-top: 1px;
    }

    &__title {
      margin-left: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1em;
    }

    &:disabled &__number {
      background-color: white;
      color: $dark;
    }

    &--active:disabled &__number {
      background-color: $pulsate-blue;
      color: white;
      opacity: 1;
    }

    &--active &__number {
      background-color: $pulsate-blue;
      color: white;

      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        display: block;
        border-top: 14px solid $link-water-gray;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        transform: translate(-50%, -6px);
        transition: 300ms;
      }
    }

    &__arrow {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }

    &__arrowhead {
      position: relative;
      left: -4px;
      z-index: 10;
    }

    &__line {
      background-color: $pulsate-blue;
      height: 1px;
      width: 100%;
    }
  }
}
