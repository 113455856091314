@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.toggle {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.toggle__DND {
  margin-right: 20px;
  margin-left: 13px;
  display: inline-block;
  height: 18px;
  width: 11px;
  background-image: radial-gradient($bali-hai-blue 35%, transparent 50%);
  background-position: 0 0;
  background-size: 5px 6px;
  cursor: grab;
}

.list-header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 10px;
}
