@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.avatar-wrapper {
  height: 50px;
  width: 50px;
  align-items: stretch;
  background-color: transparent;
  border-radius: 50%;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  border: none;
  margin: 0 auto;
  padding: 0px;
}

.avatar {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

:global {
  .edit-add-admin-modal {
    .modal-dialog {
      max-width: fit-content;
    }
    .modal-header {
      padding-bottom: 10px !important;

      .close {
        padding-top: 5px;
      }
    }
  }
}

.email-address-input {
  min-width: 260px;
}