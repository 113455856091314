@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #eUevQJY3zDg1 {
    pointer-events: all
  }
  //
  //#eUevQJY3zDg1 * {
  //  animation-play-state: paused !important
  //}

  #eUevQJY3zDg1 * {
    animation-play-state: running !important
  }

  #eUevQJY3zDg5_to {
    animation: eUevQJY3zDg5_to__to 2000ms linear infinite normal forwards
  }

  #eUevQJY3zDg5 {
    animation: eUevQJY3zDg5_c_o 2000ms linear infinite normal forwards
  }
}

@keyframes :global(eUevQJY3zDg5_to__to) {
  0% {
    transform: translate(36px, 52.85355px)
  }
  25% {
    transform: translate(36px, 37.85355px)
  }
  100% {
    transform: translate(36px, 37.85355px)
  }
}

@keyframes :global(eUevQJY3zDg5_c_o) {
  0% {
    opacity: 0
  }
  14% {
    opacity: 0.2
  }
  25% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}