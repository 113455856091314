@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.report-btn {
  background: white;
  width: 104px;

  &--compact {
    width: 85px;
  }

  &:hover {
    box-shadow: 0 4px 4px #879bb7;
  }

  &:disabled,
  &--disabled {
    border: 1px solid var(--bs-btn-disabled-border-color) !important;
  }
}
