@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.bar {
  padding: 23px 36px 23px 25px;
  margin: -48px -24px 55px -24px;
}

.btn {
  color: white;
  border-color: white;
  font-weight: 500;

  &:hover:not(:disabled):not(.disabled) {
    box-shadow: 0 4px 4px rgba(40, 81, 140, 0.5);
    color: white;
  }
}