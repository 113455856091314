@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.merge-tags-list-wrapper {
  overflow-y: auto;
  height: 70vh;
}

.merge-tag-tile {
  box-shadow: 0 1px 3px rgba($body-color, 0.25);
  transition: box-shadow 0.3s;

  border: 2px solid transparent;
  padding: 16px 15px;

  &:hover {
    box-shadow: 0 4px 4px $bali-hai;
  }
}

.merge-tag-value {
  max-width: 230px;
}

.merge-tag-default {
  width: fit-content;
  background-image: linear-gradient(270deg, rgba(102, 67, 180, 0.2) 0%, rgba(147, 168, 197, 0.2) 100%);
  border-radius: 3px;
}

.selected-merge-tag {
  outline: none;
  border: 2px solid $body-color;
  padding: 15px 15px;
  transition: border 0.3s;

  :focus {
    box-shadow: none;
  }
}

.alternative-text-control {
    min-width: 195px;
}

:global {
  .merge-tags-modal {
    .modal-dialog {
      max-width: 700px;
    }
  }
}