@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
$dashedDividerHeight: 60px; //px

.edit-btn {
  height: 26px;
  line-height: 1px;
}

:global {
  .muted {
    font-size: 13px;
    line-height: 19.5px;
    color: $blue-gray;
  }

  .review-container {
    background-color: white;
    border: 1px solid $bali-hai-blue;
    border-radius: 3px;
    padding: 75px 55px;

    @include media-breakpoint-up(xxxl) {
      width: 80%;
    }

    &__header {
      font-size: 18px !important;
      font-weight: 400 !important;
      color: $blue-gray !important;
    }
  }

  .section-card {
    max-width: 800px;
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    background-color: $alabaster;
    border: 1px solid $bali-hai-blue;
    border-radius: 3px;
    margin: 0 auto;

    &--limits {
      padding: 30px 0 25px 77px;
      font-size: 16px;
      margin-bottom: $dashedDividerHeight;
      position: relative;

      &::after {
        content: '';
        bottom: -$dashedDividerHeight;
        height: $dashedDividerHeight;
        border: 1px dashed $light-pulsate-blue;
        position: absolute;
        left: 50%;
      }
    }

    &--times {
      padding: 15px 0;
    }
  }
}
