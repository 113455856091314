@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .beacons {
    &__table-wrapper {
      margin-bottom: 350px;
    }

    &__status-divider {
      height: 17px;
      width: 1px;
      border: 1px solid $mystic-gray;
    }
  }

  .batch-create-beacons-error-modal,
  .add-beacon-modal {
    .modal-dialog {
      max-width: 750px;
    }
  }
}