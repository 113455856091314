@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .dnd {
    &__hover {
      background-color: $botticelli;
      transition: 200ms;

      & * {
        pointer-events: none;
      }

      input[type='file'] {
        pointer-events: all;
      }
    }
  }

  .mobile-panel {
    &__icon {
      width: 20px;
      height: 28px;
    }
  }

  .admin-option {
    white-space: nowrap;

    &--hover {
      transition: 200ms;
    }

    &--hover:hover {
      background-color: #deebff;
    }

    &__avatar {
      margin-right: 0.5em;
      padding: 2px;
      width: 34px;
      height: 34px;
      background-image: linear-gradient(to bottom, #c04db9, #623eb2);
      border-radius: 50%;
      overflow: hidden;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  .cta {
    &__item {
      padding: 10px;
      padding-top: 18px;
      height: 100%;
      font-size: 12px;

      @media all and (min-width: 1350px) {
        font-size: 14px;
      }

      &:focus,
      &:hover,
      &--active {
        box-shadow: $btn-box-shadow;
      }
    }

    &__icon {
      display: inline-block;
      width: 35px;
      height: 25px;
      background: center / contain no-repeat;

      @media all and (min-width: 1350px) {
        width: 50px;
        height: 40px;
      }
    }
  }

  .variants {
    $self: &;

    margin-left: -1px;
    margin-right: -1px;

    &__tab {
      position: relative;
      margin-left: 1px;
      margin-right: 1px;
      background-color: $white-lilac;
      border-radius: 0;
      flex: 0 1 25%;
      font-size: 1rem;

      &--cg {
        background-color: #bababa;
      }

      &--active {
        box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12);
      }

    }

    &__btn {
      font-size: 1rem;
      transition: 200ms;

      &:focus {
        box-shadow: none;
      }
    }

    &__remove {
      position: absolute;
      right: 10%;
      top: 50%;
      opacity: 0;
      transform: translate(5px, -50%);
      transition: 200ms;
    }

    &__id {
      font-size: 18px;

      @include media-breakpoint-up(xl) {
        font-size: 22px;
      }
    }

    &__dropdown {
      transition: 200ms;

      &:hover {
        opacity: 1;
      }
    }

    &__tab--hover:hover {
      #{$self}__btn {
        transform: translateX(-5px);

        @include media-breakpoint-up(xxxl) {
          transform: translateX(-10px);
        }
      }

      #{$self}__remove {
        opacity: 1;
        transform: translate(2px, -50%);
        @include media-breakpoint-up(
          xxxl,
          // (
          //   xxl: 1600px
          // )
        ) {
          transform: translate(0, -50%);
        }
      }
    }
  }
}
