@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.header {
  color: $fuchsia-pink !important;
}

.use-btn {
  width: 100px;
  background-color: white;
}

.search-input {
  min-width: 340px;
}

.table-wrapper {
  max-height: calc(100vh - 215px);
  overflow-y:scroll;

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: white;
      }
    }
  }
}

.sidebar-trigger-btn {
  background-color: $fuchsia-pink;
  color: white;

  &:hover {
    background-color: darken($fuchsia-pink, 10%);
    color: white;
  }
}

.campaign-name {
  padding-left: 10px;
  color: $fuchsia-pink;
  a {
    color: $fuchsia-pink;
  }
}
