.text-gray {
  color: $gray;
}

.text-primary {
  color: $primary;
}

.text-dark {
  color: $dark;
}

.text-blue {
  color: $pulsate-blue;
}

.text-blue-gray {
  color: $blue-gray;
}

.text-11 {
  font-size: 11px;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-24 {
  font-size: 24px;
}


.truncate {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;

  &--two-lines {
    -webkit-line-clamp: 2;
  }

  &--three-lines {
    -webkit-line-clamp: 3;
  }
}

.text-base {
  font-size: 1rem;
}

.break-line {
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
