@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .users-csv-upload-modal {
    .modal-dialog {
      max-width: 1357px;
    }
  }
}

.columns-wrapper {
  min-height: 170px;
  display: grid;
  grid-template-columns: 360px 1fr 390px 1fr;
  grid-gap: 0;

}

.column-separator {
  border-right: 1px $pigeon-post dashed;
  height: 100%;
}

.hint-wrapper {
  background-color: #F2F5FA;
  margin-top: 50px;
}

.number-pill {
  width: 40px;
  height: 40px;
  border: 2px solid #93A8C5;
  background: #93A8C5;
  color: white;
  font-size: 18px;
  font-weight: 600;
}