@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.page-container {
  width: 1000px;
  margin: 0 auto;
  padding: 0 12px;
}

.name-column {
  min-width: 300px;
  max-width: 430px;
  color: #3e4676;
  padding: 5px 0;
}

.mode-column {
  max-width: 185px;
}

.link-to-app {
  color: $body-color;
}

.app-image-wrapper {
  height: 38px;
  width: 38px;
  align-items: stretch;
  background-color: transparent;
  border-radius: 50%;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  border: none;
  margin: 2px;
  padding: 0px;
}

.app-image {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.mode-indicator {
  border-radius: 100%;
  display: block;
  width: 16px;
  height: 16px;
  padding-left: 15px;
}