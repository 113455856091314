.pws-img-feed {
  width: 100% !important;
}

.pulse-feed {
  display: flex;
  gap: 60px;
  flex-wrap: wrap;

  &:not(:only-child) {
    display: none;
  }
}

.pulse-feedpost {
  flex: 1 0 100%;
  display: flex;
  gap: 30px;
  height: 260px;
}

.pulse-img-box-feed {
  overflow: hidden;
  border-radius: 8px;
  flex: 1 1;
  text-align: center;
  line-height: 0;
  background-color: #ebebeb;
}

.pulse-content-box {
  padding: 2rem;
  flex: 2 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
