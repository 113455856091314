.tabs {
  border: 0;
  display: flex;
  flex-wrap: nowrap;
  &__item {
    position: relative;
    padding: 0;
    margin-right: 1em;
    background: transparent;
    border: 0;
    color: $blue-gray;

    &:hover,
    &:focus {
      outline: 0;
    }
    &--active {
      color: $pulsate-blue;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        height: 2px;
        width: 100%;
        background-color: $pulsate-blue;
      }
    }

    &:not(:first-child) {
      margin-left: 1em;
      &:before {
        content: '';
        position: absolute;
        left: -1em;
        display: block;
        height: 100%;
        width: 2px;
        background-color: $mystic-gray;
      }
    }
  }

  &--big {
    font-size: 24px;
  }

  &--button {
    flex-direction: column;
  }

  &--button &__item {
    margin: 0 1em 1em;
    padding: 10px 2px;
    min-height: 50px;
    background-color: $blue-gray;
    color: #fff;
    border-radius: 5px;
    font-size: 11px;
    box-sizing: border-box;
    font-weight: 600;

    &::before {
      display: none;
    }

    &--shadow {
      box-shadow: $btn-box-shadow;
    }

    &--active {
      box-shadow: $btn-box-shadow;
      background-color: #fff;
      color: $pulsate-blue;

      &:after {
        display: none;
      }
    }

    &-label {
      display: inline-block;
      width: 50px;
    }
  }

  &--row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &--card-switch &__item:not(:only-child):first-child {
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 2px solid #fff;
  }

  &--card-switch &__arrows {
    margin-left: 0;
    width: 25px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $blue-gray;
  }
}

.dropdown-nav-tabs {
  border: 0;
  flex-wrap: nowrap;

  &__tab {
    background: transparent;
    color: $blue-gray;

    &:hover,
    &:focus {
      outline: 0;
      border-color: transparent;
    }

    &--active {
      color: $pulsate-blue;
    }
  }

  &__separator {
    margin: 0.9rem 0;
    width: 2px;
    background-color: $mystic-gray;
  }
}

.dropdown-nav-tabs .dropdown-nav-tabs__tab {
  border: 0;
}
