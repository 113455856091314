@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.errors-container {
  overflow-y: auto;
  padding-left: 17px;
  max-height: 356px;
}

.error-item {
  font-size: 18px;
  color: $danger;
}

