@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.table {
  $self: &;
  font-size: 16px;

  &__header {
    font-size: 14px;
  }

  &__space-left {
    position: relative;
    padding-left: 30px;
  }

  &__x-line {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0.7em;
      left: 12px;
      width: 12px;
      height: 2px;
      background-color: $blue-gray;
    }
  }

  &__row {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      background-color: $blue-gray;
    }
  }

  &__row:first-child::before {
    top: 2.6em;
  }

  &__row:last-child::before {
    bottom: 2.15em;
  }

  &__arrow {
    transition: 200ms;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__striped:nth-child(odd) &__striped-item {
    background-color: $link-water-gray;
  }

  &__conversion-col {
    margin-left: 32px;
  }
}

.external-conversion {
  font-size: 24px;
}
