@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.list-header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 30px;
}

.table {
  padding: 0 !important;

  td {
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border: 0 !important;
  }

  th {
    &:nth-child(2) {
      text-align: right;
    }
  }
}

@mixin dwelling {
  color: $light-pulsate-blue;
  font-size: 13px;
  line-height: 19.5px;
}

.dwelling-txt {
  @include dwelling;

  position: relative;
  margin-left: 1.5ch;

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 20%;
    left: -1.5ch;
    background-image: url(./dwellLine.svg);
    background-size: 6px 6px;
  }
}

.dwelling-input {
  @include dwelling;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;

  color: $dark;
  width: 30px;
  height: 24px;
  margin-top: 5px;
  margin-left: 0.75ch;
  margin-right: 0.75ch;
  padding-top: 1px;
  border: 1px solid $bali-hai-blue;
  border-radius: 3px;
  text-align: center;
}
