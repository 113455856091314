.border-body {
  border-color: $body-color !important;
}

.border-blue-gray {
  border-color: $blue-gray !important;
}

.border-gray {
  border-color: $gray !important;
}

.border-input {
  border-color: $bali-hai-blue !important;
}

.border-danger {
  border-color: $danger !important;
}
