@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
@import "~/assets/css/colors";

.content {
  padding-bottom: 20px;
}

.divider-wrapper {
  padding: 0 0 20px 0;
}

.divider {
  border-bottom: 1px solid $mystic-gray;
  width: 100%;
  display: block;
}
