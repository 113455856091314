@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.future-btn {
  min-height: 80px;

  &--active {
    box-shadow: $btn-box-shadow;
  }
}

.future-icon {
  width: 40px;
  max-height: 40px;
}
