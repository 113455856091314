@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.hint-window {
  position: fixed;
  inset: 0;
  display: none;
  // background-color: rgba($pulsate-blue, 0.86);
  z-index: 1051;
  pointer-events: none;

  &--active {
    display: block;
  }

  @for $i from 1 through 5 {
    &--active-#{$i} &__single:nth-child(#{$i}) {
      display: block;
    }
  }

  &__single {
    position: fixed;
    left: -430px;
    top: 0;
    padding: 0;
    width: 410px;
    display: none;
    flex: 1 0 100%;
    background-color: #fff;
    border: 0;
    box-shadow: 0 0 15px $pulsate-blue;
    box-sizing: border-box;
    border-radius: 7px;
    overflow: hidden;
    z-index: 999;
    pointer-events: all;
  }

  &__content {
    padding: 25px;
    background-color: $fuchsia-pink;
    color: #fff;
    box-sizing: border-box;

    & > * {
      color: #fff;
    }
  }

  &__action-btn {
    background-color: #863982;

    &:hover {
      background-color: darken(#863982, 10);
    }
  }

  &-btn {
    padding: 4px 0;
    height: 31px;
    background-color: #fff;
    border: 1px solid $blue-gray;
    border-radius: 20px;
    color: $body-color;
    font-weight: 500;
    line-height: 1;
    box-sizing: border-box;
    overflow: hidden;

    &--fixed {
      position: absolute;
      top: 140px;
      left: 30px;
      z-index: 10;
    }

    &__icon {
      width: 31px;
      flex: 0 0 31px;
    }

    &__text {
      max-width: 0.1px;
      width: 100%;
      white-space: nowrap;
      transition: 400ms ease-in-out;

      &:after {
        content: ' ';
        padding-right: 12px;
      }
    }

    &:hover &__text {
      max-width: 300px;
    }
  }
}
