@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.checkbox-input {
  display: none;
}

.checkbox-container {
  position: relative;
  display: flex;
  min-width: 22px;
  min-height: 22px;
  cursor: pointer;
  align-items:center;

  .checkbox {
    flex-shrink: 0;
    color: white;
    fill: white;
    transition: all 0.2s ease-in-out 0s;
    rect:nth-of-type(2) {
      transition: stroke 0.2s ease-in-out 0s;
      stroke: $body-color;
      stroke-opacity: 0.5;
    }
  }
}

.checkbox-input:checked + .checkbox-container .checkbox {
    fill: white;
    color: $primary;
    rect {
      stroke: $primary;
    }
  }

.label {
  display: inline-block;
  width: 100%;
  margin-left: 10px;
}
