.tooltip-error .tooltip {
  z-index: 999;
}

.tooltip-error--modal .tooltip {
  z-index: 999999;
}

.tooltip-error,
.tooltip-validation-error {
  .tooltip {
    padding-left: 0;

    .tooltip-inner {
      background-color: $danger;
      padding: 10px;
    }

    &.bs-tooltip-auto[x-placement^='top'] .arrow::before {
      border-top-color: $danger;
    }

    &.bs-tooltip-auto[x-placement^='right'] .arrow::before {
      border-right-color: $danger;
    }

    &.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
      border-bottom-color: $danger;
    }

    &.bs-tooltip-auto[x-placement^='left'] .arrow::before {
      border-left-color: $danger;
    }
  }
}

.tooltip-validation-error {
  .tooltip {
    &.show {
      opacity: 1;
    }

    .tooltip-inner {
      text-align: left;
      padding: 7.5px 15px;
      max-width: 400px;
    }

    .arrow {
      display: none;
    }
  }
}

.tooltip-error {
  .tooltip {
    .tooltip-inner {
      padding: 15px;
      max-width: 300px;
    }
  }

  &__header {
    opacity: 0.7;
    font-size: 14px;
    text-align: left;
  }

  &__content {
    text-align: left;
    font-size: 13px;
  }

  &__close {
    position: absolute;
    right: 12px;
    width: 12px;
    height: 15px;
    cursor: pointer;
    z-index: 2;
  }
}

.tooltip-image-preview {
  border: 0;
  box-shadow: 0 10px 18px rgba(31, 38, 58, 0.35);
  max-height: 400px;
  min-width: 380px;
  overflow: auto;

  .popover-body {
    padding: 0;
    text-align: center;
  }
}

.tooltip-device-zoom {
  background-color: transparent;
  border: 0;
  max-width: 390px;
}

.tooltip-modern {
  --bs-tooltip-opacity: 1;
  font-size: 15px;

  .tooltip-inner {
    min-width: 335px;
    background: $pulsate-blue;
    box-shadow: 0 10px 20px 0 #C050BA33;
    padding: 15px;
    color: #D4E2FF !important;
    letter-spacing: 0.2px;
    text-align: left;
    line-height: 25px;

    strong {
      color: #fff;
    }
  }

  &.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    --bs-tooltip-bg: $pulsate-blue !important;
    --bs-tooltip-arrow-height: 15px;
    --bs-tooltip-arrow-width: 25px;
  }
}
