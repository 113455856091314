@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .battery-status-block {
    background-color: $blue-gray;
    border-radius: 3px;
    display: flex;
    align-items: center;

    &__label {
      color: white;
    }

    &__status {
      margin: 3px;
      display: flex;
      align-items: center;
      background-color: $light-grayish-blue;
    }

    &__status,
    &__label {
      padding: 0.5em 1em;
    }
  }
}