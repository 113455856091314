@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
@import "~/assets/css/colors";

.toggleButtonsSeparator {
  width: 1px;
  height: 17px;
  background-color: $mystic-gray;
  display: inline-block;
}
