@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #eVdz01YvgjJ1 {
    pointer-events: all
  }

  #eVdz01YvgjJ4_to {
    animation: eVdz01YvgjJ4_to__to 2000ms linear infinite normal forwards
  }

  #eVdz01YvgjJ4 {
    animation: eVdz01YvgjJ4_c_o 2000ms linear infinite normal forwards
  }

  #eVdz01YvgjJ9_to {
    animation: eVdz01YvgjJ9_to__to 2000ms linear infinite normal forwards
  }

  #eVdz01YvgjJ14_ts {
    animation: eVdz01YvgjJ14_ts__ts 2000ms linear infinite normal forwards
  }

  #eVdz01YvgjJ14 {
    animation: eVdz01YvgjJ14_c_o 2000ms linear infinite normal forwards
  }

  #eVdz01YvgjJ9 {
    animation: eVdz01YvgjJ9_c_o 2000ms linear infinite normal forwards
  }
}

@keyframes :global(eVdz01YvgjJ4_to__to) {
  0% {
    transform: translate(95.5px, 52px)
  }
  15% {
    transform: translate(36px, 52px)
  }
  100% {
    transform: translate(36px, 52px)
  }
}

@keyframes :global(eVdz01YvgjJ4_c_o) {
  0% {
    opacity: 0
  }
  5% {
    opacity: 0.07
  }
  15% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(eVdz01YvgjJ9_to__to) {
  0% {
    transform: translate(-24px, 94px)
  }
  15% {
    transform: translate(-23.5px, 94px)
  }
  40% {
    transform: translate(36px, 94px)
  }
  100% {
    transform: translate(36px, 94px)
  }
}


@keyframes :global(eVdz01YvgjJ9_c_o) {
  0% {
    opacity: 0
  }
  25% {
    opacity: 0.23
  }
  40% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}



@keyframes :global(eVdz01YvgjJ14_ts__ts) {
  0% {
    transform: translate(36.2221px, 26.824px) scale(0.2, 1)
  }
  15% {
    transform: translate(36.2221px, 26.824px) scale(1, 1)
  }
  100% {
    transform: translate(36.2221px, 26.824px) scale(1, 1)
  }
}

@keyframes :global(eVdz01YvgjJ14_c_o) {
  0% {
    opacity: 0
  }
  15% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}