@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.avatar-wrapper {
  height: 100px;
  width: 100px;
  align-items: stretch;
  background-color: transparent;
  border-radius: 50%;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  border: none;
  margin: 10px auto 0px auto;
  padding: 0px;
}

.avatar {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5,minmax(0,1fr));
}

.list-item {
  padding: 20px 20px 30px 20px;
  background-color: white;
  text-align: center;
  position: relative;
  margin-bottom: 0 !important;

  &:hover {
    .action {
      opacity: 1;
    }
  }
}

.action {
  padding: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  justify-content: center;
  background-color: white;
  border: 1px solid $primary;
  border-radius: 3px;
  text-align: center;
  color: $pulsate-blue;
  transition: opacity 0.3s, box-shadow 0.3s;
  z-index:20;
  opacity: 0;

  &:focus {
    color: $pulsate-blue;
    box-shadow: none;
  }
}

.actionDelete {
  right: 15px;
}

.actionEdit {
  left: 15px;
}

.admin-username {
  word-break: break-all;
}