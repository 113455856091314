@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.auth-page {
  background: no-repeat top center fixed;
  background-color: $body-color;
  background-size: cover;
  height: 100%;
  padding: 58px 0 58px 140px;
  min-height: 100vh;


  @include media-breakpoint-up('xxl') {
    padding: 120px 0 58px 115px;
  }

  @include media-breakpoint-up('hd') {
    padding: 150px 0 58px 160px;
  }
}

.auth-page-registration {
  padding-top: 45px;
}

.form-wrapper {
  background-color: white;
  width: 411px;
  min-height: 400px;
  padding: 56px 30px 60px 30px;
  display: block;
  box-shadow: 0 4px 4px 0 rgba($body-color, 6.25%);
}

.form {
  padding-top: 43px;
}

.btn-focus {
  &:focus-visible {
    color: white;
  }
}