// Used for the New App overlay that needs to look the same as the Rails pages
// TODO: Move to using one of the Spark theme fonts when possible
@font-face {
  font-family: 'Proxima Nova';
  src: url(../fonts/old_rails_fonts/proximanova-bold-webfont.eot);
  src:
    url(../fonts/old_rails_fonts/proximanova-bold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/old_rails_fonts/proximanova-bold-webfont.woff) format('woff'),
    url(../fonts/old_rails_fonts/proximanova-bold-webfont.ttf) format('truetype'),
    url(../fonts/old_rails_fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold) format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../fonts/old_rails_fonts/proximanova-boldit-webfont.eot);
  src:
    url(../fonts/old_rails_fonts/proximanova-boldit-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/old_rails_fonts/proximanova-boldit-webfont.woff) format('woff'),
    url(../fonts/old_rails_fonts/proximanova-boldit-webfont.ttf) format('truetype'),
    url(../fonts/old_rails_fonts/proximanova-boldit-webfont.svg#proxima_nova_rgbold_italic) format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../fonts/old_rails_fonts/proximanova-light-webfont.eot);
  src:
    url(../fonts/old_rails_fonts/proximanova-light-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/old_rails_fonts/proximanova-light-webfont.woff) format('woff'),
    url(../fonts/old_rails_fonts/proximanova-light-webfont.ttf) format('truetype'),
    url(../fonts/old_rails_fonts/proximanova-light-webfont.svg#proxima_novalight) format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../fonts/old_rails_fonts/proximanova-regular-webfont.eot);
  src:
    url(../fonts/old_rails_fonts/proximanova-regular-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/old_rails_fonts/proximanova-regular-webfont.woff) format('woff'),
    url(../fonts/old_rails_fonts/proximanova-regular-webfont.ttf) format('truetype'),
    url(../fonts/old_rails_fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../fonts/old_rails_fonts/proximanova-regularitalic-webfont.eot);
  src:
    url(../fonts/old_rails_fonts/proximanova-regularitalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/old_rails_fonts/proximanova-regularitalic-webfont.woff) format('woff'),
    url(../fonts/old_rails_fonts/proximanova-regularitalic-webfont.ttf) format('truetype'),
    url(../fonts/old_rails_fonts/proximanova-regularitalic-webfont.svg#proxima_novaregular_italic) format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(../fonts/old_rails_fonts/proximanova-semibold-webfont.eot);
  src:
    url(../fonts/old_rails_fonts/proximanova-semibold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/old_rails_fonts/proximanova-semibold-webfont.woff) format('woff'),
    url(../fonts/old_rails_fonts/proximanova-semibold-webfont.ttf) format('truetype'),
    url(../fonts/old_rails_fonts/proximanova-semibold-webfont.svg#proxima_novasemibold) format('svg');
  font-weight: 600;
  font-style: normal;
}
