@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.custom-datepicker-wrapper {
  .datepicker-input {
    width: 100px;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 0;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $input-border-color;
    }
  }

  .datepicker-input-icon {
    display: block;
    background-color: white;
    border-left: 0;
    height: 100%;
    padding: 6px 20px 8px 1px;
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

:global {
  .range-datepicker-dropdown {
    // override .btn-group border-radius reset
    > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &__icon {
      margin-top: -1px;
      margin-right: 6px;
      width: 13px;
      height: 15px;
    }
  }

  .range-datepicker-input {
    background-color: #fff;
    height: 39px;
    padding: 8px 10px 8px 20px;
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
    border: 1px solid $pulsate-blue;
    border-right: 0;
    white-space: nowrap;

    &__icon {
      border: 1px solid $pulsate-blue;
      border-left: 0;
    }
  }

  .range-datepicker {
    &__calendar-wrapper {
      flex-wrap: nowrap;
      display: flex;
      border-bottom: 1px solid rgba($pulsate-blue, 0.5);
      border-top: 1px solid rgba($pulsate-blue, 0.5);
    }
    &__header {
      position: relative;
      padding: 10px 10px 0;
      font-size: 18px;
      color: $blue-gray;
      z-index: 2;
    }
    &__calendar {
      margin-top: -20px;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      .react-datepicker__month-container {
        float: none;
        padding: 0 10px;
      }
      .react-datepicker__month {
        margin-bottom: 0;
      }
    }

    &__presets {
      margin: 0;
      padding: 0;
      list-style-type: none;
      min-width: 370px;
      border-top: 1px solid rgba($pulsate-blue, 0.8);

      &-item {
        padding: 8px 16px;
        width: 100%;
        border: 0;
        outline: 0;
        background-color: transparent;
        text-align: left;
        color: $pulsate-blue;
        box-sizing: border-box;
        transition: 200ms;

        &:disabled {
          opacity: 0.5;
        }

        &:hover {
          background-color: $light;
        }
      }
    }
  }
}
