@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.sidebar {
  &__block {
    padding: 20px 0 60px;
    overflow-y: auto;
    box-sizing: border-box;
  }

  &__btns {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 20px 20px;
    background-color: $link-water-gray;
    z-index: 9;
  }

  &--active {
    transform: translateX(0);
  }

  &--email-active {
    .sidebar__close {
      margin: 10px 5px;
    }
  }
}
