@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.graph-card {
  padding: 20px 23px 28px 23px;
  margin-bottom: 0;
  height: 100%;
  justify-content: space-between;
}

.graph-title {
    font-size: 18px;
    margin-bottom: 0;
    width: fit-content;
    @include media-breakpoint-between('xxl', 'xxxl') {
      font-size: 16px;
    }
}

.graph-diff {
  display: flex;
  align-items: center;
  margin-left:5px;
  font-size: 14px;
  font-weight: 600;

    &-positive {
      color: $info;

      .graph-diff-icon {
        &::after {
          content: '';
          margin: 0 4px 0 2px;
          display: inline-block;
          border-bottom: 0.8em solid $info;
          border-left: 0.5em solid transparent;
          border-right: 0.5em solid transparent;
        }
      }
    }

    &-negative {
      color: $danger;
      &::after {
        content: '';
        margin: 0 4px 0 2px;
        display: inline-block;
        border-top: 0.8em solid $danger;
        border-left: 0.5em solid transparent;
        border-right: 0.5em solid transparent;
      }
    }
}

.graph-diff-value {
  font-size: 18px;
}

.graph-diff-icon {
  font-size: 13px;
}