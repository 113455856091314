@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.color-picker {
  width: 34px;
  height: 34px;
  display: flex ;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 100%;

  &:hover {
    background-color: rgba($body-color, 0.3);
  }

  svg {
    padding: 0;
  }
}
