.preview-img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: top;
}

.preview-device {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  $self: &;

  &__img {
    height: 100%;
    width: 100%;

    @include media-breakpoint-up('xxl') {
      height: 340px;
      width: auto;
    }
  }

  &__box {
    position: absolute;
    inset: 0;
  }

  &__content {
    position: absolute;
    top: 15%;
    left: 3.83%;
    right: 4.33%;
    bottom: 2.7%;
    overflow: hidden;
  }

  &--watch {
    align-items: center;

    #{$self}__img {
      min-height: 230px;
      height: auto;
    }

    #{$self}__box {
      top: 20%;
      left: 0;
      right: 4%;
      bottom: 20%;
      width: auto;
      height: auto;
    }

    #{$self}__content {
      top: 17%;
      left: 7%;
      right: 7%;
      bottom: 15%;
    }

    &#{$self}--lg #{$self}__img {
      width: 26vmin;
      min-height: auto;

      @media all and (min-width: 1500px) {
        width: 23vmin;
        max-width: 240px;
        min-height: auto;
      }
    }
  }

  &--desktop {
    #{$self}__img {
      width: 100%;
      max-width: 700px;
      height: auto;

      @include media-breakpoint-up('xxl') {
        min-height: auto;
        width: 100%;
      }
    }

    #{$self}__box {
      padding-top: 57.5%;
      bottom: auto;
    }

    #{$self}__content {
      top: 3%;
      left: 2%;
      right: 1.7%;
      bottom: 3%;
      background-color: $concrete;
      overflow-y: hidden;
    }
  }

  &--xs {
    min-height: 350px;

    #{$self}__img {
      height: auto;
      min-height: auto;
      width: 183px;
    }

    &#{$self}--watch #{$self}__img {
      min-height: 260px;
      height: auto;
      width: auto;
    }

    .preview {
      font-size: 7px;
    }
  }

  &--sm {
    min-height: 477px;

    #{$self}__img {
      height: auto;
      min-height: auto;
      width: 250px;
    }

    &#{$self}--watch #{$self}__img {
      min-height: 260px;
      height: auto;
      width: auto;
    }

    .preview {
      font-size: 8px;
    }
  }

  &--lg {
    #{$self}__img {
      height: auto;
      min-height: auto;
      width: 303px;
    }

    .preview {
      font-size: 10px;

      .preview__card--small.preview__card--ios.preview__card--bottom {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }

  &--in_app {
    #{$self}__content {
      left: 2.5%;
      right: 2.5%;
      top: 14.9%;
      bottom: 1.5%;
    }
  }

  &--xl {
    #{$self}__img {
      height: auto;
      min-height: auto;
      width: 100%;
      max-width: 500px;
    }
  }

  &--xxl {
    #{$self}__img {
      height: auto;
      min-height: auto;
      width: 700px;
    }

    .email-iframe {
      width: 170%;
      height: 170%;
      transform: scale(0.6);
      transform-origin: top left;
    }
  }

  // Zoom preview
  &--zoom {
    background-color: transparent;
    border: 0;
    max-width: 390px;

    #{$self}__img {
      height: auto;
      min-height: auto;
      width: 40vmin;
      max-width: 400px;
    }

    #{$self}--sm #{$self}__img {
      max-width: 300px;
    }

    #{$self}--xl #{$self}__img {
      max-width: 500px;
      width: 50vmin;
    }

    .preview {
      font-size: 12px;

      .preview__card--small.preview__card--ios.preview__card--bottom {
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
      }

      @supports (font-size: 1vmin) {
        font-size: 1.112vmin;
      }
    }

    #{$self}--sm .preview {
      font-size: 10px;

      @supports (font-size: 1vmin) {
        font-size: 1.112vmin;
      }
    }

    #{$self}--watch #{$self}__img {
      width: 33vmin;

      @media all and (min-width: 1400px) {
        width: 27vmin;
      }
    }

    #{$self}--desktop .email-iframe {
      width: 250%;
      height: 250%;
      transform: scale(0.4);

      @media all and (min-width: 1400px) {
        width: 200%;
        height: 200%;
        transform: scale(0.5);
      }
    }
  }

  &--cardback &__content {
    top: 7%;
    left: 3.1%;
    right: 2.9%;
  }

  &--cardback#{&}--android &__content {
    bottom: 1.7%;
  }


}

.preview-switch-wrapper {
  bottom: 0.5em;
  left: 50%;
  transform: translateX(-50%);

  &--under {
    bottom: -70px;
  }
}

.preview-switch {
  $self: &;
  border-radius: 50%;
  background-color: $blue-gray;
  width: 40px;
  height: 40px;
  background-size: auto 60%;

  &:hover:not(#{$self}--active) {
    background-color: $blue-gray;
  }

  &:focus {
    box-shadow: 0 4px 4px $bali-hai;
  }

  &--active {
    background-color: #fff;
    box-shadow: 0 4px 4px $bali-hai;
  }
}

.device-logo {
  &__name {
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    color: $mystic-gray;
  }
}

.preview {
  position: relative;
  // margin-right: -1px;
  padding: 0 3%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: $text;
  font-size: 6px;
  box-sizing: border-box;

  // FF styles for hiding scrollbar
  // @supports (scrollbar-width: none) {
  //   scrollbar-width: none;
  //   margin-right: 0;
  // }

  &--no-gap {
    padding: 0;
  }

  &__exit {
    position: relative;
    margin-left: auto;
    display: block;
    width: 2em;
    height: 2em;
    transform: translate(0, 50%);
    background-color: #000;
    border-radius: 50%;
    border: 2px solid #fff;
    z-index: 3;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 1em;
      height: 1px;
      background-color: #fff;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__white-exit {
    position: relative;
    margin-left: auto;
    margin-bottom: 2%;
    margin-right: 2%;
    display: block;
    width: 2em;
    height: 2em;
    background-color: rgba(#fff, 0.5);
    border-radius: 50%;
    z-index: 3;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 1em;
      height: 1px;
      background-color: rgba(#000, 0.3);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__card {
    position: relative;
    padding: 0 4%;
    background-color: #fff;
    border-radius: 8px;
    align-self: flex-start;
    overflow: hidden;
    width: 100%;

    &--small {
      padding: 0;
      height: 7em;
      background-color: #eee;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border-radius: 0;
    }

    &--large,
    &--front {
      box-shadow: 0 4px 4px 0 rgba(#000, 0.25);
    }

    &--bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      width: auto;
    }

    &--front {
      margin-top: 5%;
      max-height: 81.5%;
      overflow-y: auto;
    }

    &--back {
      min-height: 100%;
      overflow-y: auto;
      border-radius: 0;
    }

    &--android {
      padding-bottom: 0;
    }

    &--watch {
      background-color: rgba(#fff, 0.2);
      color: #fff;
    }
  }

  &__headline {
    margin: 1% 0;
    font-weight: 500;
    font-size: 1.4em;
    line-height: 1.3;
    word-wrap: break-word;
    word-break: break-word;
  }

  &__attachment-box {
    margin-left: -4.5%;
    margin-right: -4.5%;

    + .cta-btns {
      margin-top: 4%;
    }
  }

  &__attachment {
    width: 100%;
    height: auto;
  }

  &__card--small &__attachment-box {
    margin: 0;
    height: 100%;
    flex: 0 0 7em;
  }

  &__card--small &__attachment {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__text {
    margin: 1% 0;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 1.8;
  }

  &__card--small &__text {
    margin: 2%;
    margin-left: 4%;
    max-height: 3em;
    overflow: hidden;
    font-size: 0.9em;
    line-height: normal;
  }

  &__card--small#{&}__card--ios &__text {
    font-size: 1.1em;
    max-height: 4.5em;
  }

  &__card--large &__headline,
  &__card--large &__text {
    text-align: center;
  }

  &__card--large &__headline {
    margin: 0.7em 0;
  }

  &__card--large &__text {
    margin: 1.5em 0;
  }

  &__tag {
    padding: 0 4px;
    display: inline-block;
    background-color: $fuchsia-pink;
    color: #fff;
    font-weight: 400;
    border-radius: 3px;
    line-height: 1.6;

    &--editable {
      cursor: pointer;
      transition: 200ms;

      &:hover {
        box-shadow: $btn-box-shadow;
      }
    }
  }

  //? modifiers for card front campaign

  &__card--front &__headline {
    margin: 3% 0;
  }
  &__card--front#{&}__card--android &__headline {
    font-size: 1.3em;
  }
  &__card--front &__text {
    margin: 3% 0;
    font-size: 1.1em;
  }

  //? modifiers for card back campaign

  &__card--back &__headline {
    margin: 3% 0;
    font-size: 1.6em;
  }
  &__card--back#{&}__card--android &__headline {
    font-size: 1.5em;
  }
  &__card--back &__text {
    margin: 3% 0;
    font-size: 1.1em;
  }

  //? modifiers for push campaign
  &__card--push#{&}__card--ios {
    padding: 0 5%;
  }

  &__card--push#{&}__card--ios &__attachment-box {
    margin-left: -6%;
    margin-right: -6%;
    margin-bottom: 4%;
  }

  &__card--push#{&}__card--ios &__text {
    margin-bottom: 4%;
  }

  &__card--push#{&}__card--android &__attachment-box {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 4%;
  }

  &__card--push#{&}__card--android &__attachment {
    height: 100%;
    max-height: 100px;
    object-fit: cover;
    object-position: center;
  }

  &__card--push#{&}__card--watch &__headline {
    font-size: 1.2em;
  }

  // ? modifier added only for SMS campaign
  &__card--sms#{&}__card--android &__attachment-box {
    margin: 0;
    margin-bottom: 8px;
  }

  //? modifiers added only for Q2 builder
  &--q2 {
    .preview__card--large {
      .cta-btns {
        flex-direction: column-reverse;
      }
    }
  }

  //? modifiers only for in_app small android bottom
  &__card--small#{&}__card--android#{&}__card--bottom {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &__card--small#{&}__card--ios#{&}__card--bottom {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &__card--sms#{&}__card--android &__text {
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-word;
  }

  &__card--sms#{&}__card--ios &__text,
  &__card--sms#{&}__card--watch &__text {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-word;
  }

  &__card--sms & {
    &__headline {
      margin: 1.5% 0;
      font-size: 1.1em;
    }
  }
}

//TODO: set sizing for different parent component size
.email-iframe-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &__mask {
    display: block;
    position: absolute;
    inset: 0;
  }
}

.email-iframe {
  width: 300%;
  height: 300%;
  transform: scale(0.3333);
  transform-origin: top left;
}

.personal-message {
  padding: 3% 0;
  color: #303030;

  &__avatar {
    margin-right: 3%;
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
  }

  &__name {
    margin-bottom: 0;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__job {
    font-size: 1.1em;
    color: $silver-gray;
  }

  &__message {
    font-size: 1em;
    white-space: pre-wrap;
    word-break: break-all;
  }

  .preview--small &__avatar {
    height: 1.6em;
    width: 1.6em;
  }

  .preview__card--small & {
    padding: 3%;
  }


  .card-back & {
    height: 4em;
    padding: 0 0 0 8%;
    margin: 2.5px -5px 0 -5px;
    overflow: hidden;
    position: relative;

    &__name,
    &__job {
      display: none;
    }

    &__message {
      @extend .truncate;
      @extend .truncate--two-lines;
    }
  }

  .card-back--ios & {
    &::before {
      content: '';
      position: absolute;
      left: 3%;
      top: 50%;
      width: 0.7em;
      height: 0.7em;
      display: block;
      border-left: 1px solid #777;
      border-bottom: 1px solid #777;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  .card-back--android & {
    &::before {
      content: '';
      position: absolute;
      left: 3%;
      top: 50%;
      width: 1em;
      height: 1em;
      display: block;
      background: url('../../components/icons/arrow_left.svg') center / contain
        no-repeat;
      transform: translateY(-50%);
    }
  }

  .card-back--reply &__message {
    max-width: 80%;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 1.9em;
      height: 1.6em;
      background: center / contain no-repeat;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAYAAAACsSQRAAAAoklEQVR4AWPABnir/jMC8XQG8gDCACD+T7EBIEyRATBMmQEIHArESuQZgInvAnEzEEviMgRhAGH8DYhbgJgd3ZAOkAIS8VkglibsHcL4FhALEWPQEiDeAsQ3gfgvFvkN2AJ4MbIiNHleaGxtBOJ/SOrs0Q1iBuKlWAxBV6cDxCeg6pZDRDENWofTEEwLn+NSwAbEm4hIY6xAfAafAjYiU7s3AJ7XHBPe9vA/AAAAAElFTkSuQmCC');
      transform: translateY(-50%);
    }
  }
}

.cta-btns {
  flex-direction: column;

  &__item {
    margin-bottom: 4%;
    padding: 0 2%;
    flex: 1;
    background-color: $azure;
    color: #fff;
    font-size: 1.3em;
    text-align: center;
    line-height: 3em;
    border-radius: 5px;
    word-break: break-all;
    min-width: 0;
    @extend .truncate;

    :is(.preview__card--front, .preview__card--large) &:not(:first-child) {
      color: $azure;
      background-color: #fff;
      border: 1px solid #3e95d1;
    }

    .card-back & {
      margin-bottom: 0;
      font-size: 1.2em;
      border-radius: 0;
    }
    .card-back--ios & {
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 0.6em;
      background-color: #0062fe;
    }
    .card-back--android & {
      padding-top: 3%;
      padding-bottom: 3%;
      font-size: 1em;
      background-color: #0b7aff;
    }
  }

  .card-back & {
    flex-direction: row;
  }

  .card-back--ios & {
    padding: 0 3%;
  }

  .card-back--android & {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
}

.action-btns {
  background-color: rgba(#fff, 0.6);
  border-radius: 5px;
  margin: 8px 0;

  &__item {
    text-align: center;
    font-size: 1.2em;
    line-height: 3.2em;
    font-weight: 500;

    &:not(:first-child) {
      border-top: 1px solid $border;
    }
  }

  &__icon {
    vertical-align: middle;
    width: 1.8em;
    height: 1.8em;
    font-size: inherit;
  }

  .preview--small &__icon {
    font-size: inherit;
    width: 1.5em;
    height: 1.5em;
  }

  &--android {
    margin: 0;
    margin-top: -4px;
    padding-top: 4px;
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &--android &__item {
    margin: 0;
    margin-left: 10px;
    border: 0;
    text-transform: uppercase;
    color: #727272;
  }

  &--watch {
    background-color: #aba9aa;
  }

  &--watch &__item {
    font-size: 1.2em;
    color: #fff;
  }
}

.app-header {
  margin: 0 -5px;
  padding: 4% 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    height: 100%;
    max-height: 1.5em;
    width: auto;
  }

  &__name {
    margin-left: 3%;
    font-size: 1em;
    line-height: 2em;
    text-transform: uppercase;
    font-weight: 500;
    color: #8e8e93;
    flex: 1;
  }

  &__close {
    position: relative;
    display: inline-block;
    width: 1.2em;
    height: 1.2em;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      background-color: $gray;
      height: 1px;
    }

    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  .preview__card--watch & {
    &__name {
      color: #fff;
    }

    &__close {
      display: none;
    }
  }

  .preview__card--push & {
    &__close::before {
      display: none;
    }

    &__close::after {
      content: '...';
      left: 50%;
      top: 50%;
      display: block;
      height: 100%;
      width: auto;
      background-color: transparent;
      font-size: 22px;
      line-height: 0;
      color: #c4c4c4;
      transform: translate(-50%, -60%);
    }
  }

  .preview__card--push.preview__card--android & {
    &__close::after {
      content: '';
      left: 50%;
      top: 50%;
      display: block;
      height: 60%;
      width: 60%;
      background-color: transparent;
      border-right: 1px solid #c4c4c4;
      border-top: 1px solid #c4c4c4;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .preview__card--push.preview__card--watch & {
    position: relative;
    margin: 0 -5%;
    padding: 0;
    // border-top: 1.5em solid #333;

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background-color: #333;
      z-index: 0;
    }

    &__logo-wrapper {
      position: absolute;
      top: 0;
      left: 4%;
      padding: 4.5%;
      width: 26%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(to bottom, #fff, #e3e3e3);
      border-radius: 10px;
      box-sizing: border-box;
      z-index: 2;

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }

    &__logo {
      width: 100%;
      height: auto;
      max-height: none;
    }

    &__name {
      position: relative;
      margin: 0;
      margin-top: 13%;
      padding: 0 5%;
      background-color: #a4a4a4;
      font-size: 1.2em;
      line-height: 2.4em;
      text-align: right;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.preview-table {
  margin: 3% 0;

  &__item {
    flex: 1 1;
    font-size: 1.3em;
    word-break: break-all;
    white-space: pre-wrap;

    &:first-child {
      color: #adadad;
      font-weight: 500;
    }
  }
}

.carousel-preview {
  margin-top: -4rem;
  height: calc(100vh - 100px - 4.5rem);
}

.carousel-inner {
  height: 100%;
  display: flex;
  align-items: center;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  display: block;
  background-color: transparent;
  border: 0;
  color: $pulsate-blue;

  &:focus {
    outline: none;
  }

  &--prev {
    left: 17%;

    @include media-breakpoint-up('xl') {
      left: 20%;
    }

    @include media-breakpoint-up('xxl') {
      left: 23%;
    }
  }

  &--next {
    right: 17%;

    @include media-breakpoint-up('xl') {
      right: 20%;
    }

    @include media-breakpoint-up('xxl') {
      right: 23%;
    }
  }

  &__chevron {
    font-size: 2rem;
  }
}

.min-h-100 {
  min-height: 100%;
}

.sms-header {
  margin: 3% 0;

  &__text,
  &__time {
    font-size: 0.9em;
  }

  &--ios {
    color: #8e8d93;
  }

  &--ios & {
    &__text {
      text-transform: uppercase;
    }

    &__time {
      margin-left: auto;
    }

    &__icon {
      margin-right: 6px;
      width: 1.2em;
      height: 1.2em;
    }
  }

  &--android & {
    &__icon {
      margin-right: 6px;
      width: 1em;
      height: 1em;
    }

    &__time {
      margin-left: 2px;
    }
  }

  &--watch {
    position: relative;
    margin: 0 -4%;
    padding: 3%;
    padding-top: calc(3% + 1.5em);
    background-color: #333;
    text-align: right;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 1.5em;
      left: 0;
      right: 0;
      background-color: rgba(#fff, 0.3);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &--watch & {
    &__text {
      margin-left: auto;
      text-transform: uppercase;
    }

    &__icon {
      position: absolute;
      left: 5px;
      top: 0;
      width: 3em;
      height: 3em;
      max-width: 40px;
      max-height: 40px;
      z-index: 2;
    }

    &__time {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.merge-tag-modal {
  max-width: 250px;
  width: 100%;

  &__default {
    width: fit-content;
    background-image: linear-gradient(
      270deg,
      rgba(102, 67, 180, 0.2) 0%,
      rgba(147, 168, 197, 0.2) 100%
    );
    border-radius: 3px;
  }
}

.audio-preview {
  height: 30px;

  &::-webkit-media-controls-panel {
    background-color: #fff;
  }

  &::-webkit-media-controls-play-button {
    background-color: #fff;
  }

  &::-webkit-media-controls-timeline {
    width: 100%;
  }

  &::-webkit-media-controls-volume-slider-container {
    width: 0;
    display: none;
  }

  &::-webkit-media-controls-mute-button,
  &::-webkit-media-controls-volume-slider,
  &::-webkit-media-controls-seek-back-button,
  &::-webkit-media-controls-seek-forward-button,
  &::-webkit-media-controls-fullscreen-button,
  &::-webkit-media-controls-rewind-button,
  &::-webkit-media-controls-return-to-realtime-button,
  &::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
}

.video-preview {
  &__btn {
    width: 25%;
    max-width: 60px;
    max-height: 60px;
    background-color: rgba(#fff, 0.35);
    border-radius: 50%;
    opacity: 0;
    transition: 200ms;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  &__control {
    display: inline-block;

    &--play {
      width: 55%;
      height: 55%;
    }

    &--pause {
      width: 70%;
      height: 70%;
    }
  }

  &__icon {
    vertical-align: baseline;
  }

  &:hover &__btn {
    opacity: 1;
  }
}

.card-back {
  overflow: hidden;

  &__scrollable {
    overflow-y: auto;
  }

  &__fixed {
    padding: 0 4%;
  }

  &--ios {
    padding-bottom: 7%;
  }
}

.card-front-tooltip-overflown {
  position: relative;
  margin-top: -3%;
  margin-left: -5%;
  margin-right: -10%;
  height: 3%;
  background-color: rgba(147, 168, 197, 0.5);
  border-top: 2px dashed #93a8c5;
}

.card-front {
  &-arrow {
    position: absolute;
    left: 2%;
    top: 1%;
    display: block;
    width: 1.2em;
    height: 1.5em;
    -webkit-filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 0;
  }

  &-tooltip .tooltip-inner {
    position: relative;
    padding-top: 10px;
    background-color: #93a8c5;
  }
  &-tooltip .arrow:before {
    border-right-color: #93a8c5 !important;
  }
}
