@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .bulk-upload-method-modal {
    .modal-dialog {
      max-width: 650px;
    }

    &__add-option {
      width: 45%;
    }

    &__add-options-divider {
      width: 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:after {
        margin-top: 5px;
      }

      &:before, &:after {
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAABSBAMAAACMFhjgAAAAIVBMVEWvx9e3xtoAAACvv9+4xtqzxtm3x9qzw9u2xtm3x9q2xthdRC6sAAAAC3RSTlMg3wAQj1C/QK+fcMqOZGcAAAA3SURBVAjXY2AEQyUgVADSMMgJFlFHEjEBi5giiViBRUyQREzBIhZIIgpgETYkESUwRPDZCNsEAKMXBQcAnhLSAAAAAElFTkSuQmCC);
      }
    }

    &__upload-error-item {
      line-height: 28px;
      font-size: 18px;
    }
  }
}