@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #pushAnimatedIcon {
    pointer-events: all
  }

  #animatedObject_to {
    animation: animatedObject_to__to 2000ms linear infinite normal forwards
  }

  #animatedObject_ts {
    animation: animatedObject_ts__ts 2000ms linear infinite normal forwards
  }

  #animatedObject {
    animation: animatedObject_c_o 2000ms linear infinite normal forwards
  }

  #animatedObject2_tr {
    animation: animatedObject2_tr__tr 2000ms linear infinite normal forwards
  }

  #animatedObject2 {
    animation: animatedObject2_c_o 2000ms linear infinite normal forwards
  }
}

@keyframes :global(animatedObject_to__to) {
  0% {
    offset-distance: 0%
  }
  25% {
    offset-distance: 100%
  }
  100% {
    offset-distance: 100%
  }
}

@keyframes :global(animatedObject_ts__ts) {
  0% {
    transform: scale(0.4, 0.4)
  }
  25% {
    transform: scale(1, 1)
  }
  100% {
    transform: scale(1, 1)
  }
}

@keyframes :global(animatedObject_c_o) {
  0% {
    opacity: 0
  }
  25% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animatedObject2_tr__tr) {
  0% {
    transform: translate(36px, 85px) rotate(0deg)
  }
  47.5% {
    transform: translate(36px, 85px) rotate(0deg)
  }
  55% {
    transform: translate(36px, 85px) rotate(-5deg)
  }
  60% {
    transform: translate(36px, 85px) rotate(5deg)
  }
  65% {
    transform: translate(36px, 85px) rotate(0deg)
  }
  100% {
    transform: translate(36px, 85px) rotate(0deg)
  }
}

@keyframes :global(animatedObject2_c_o) {
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  45% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}