$builderWidth: 881px;

.builder {
  width: $builderWidth;
  overflow-x: hidden;
  padding-left: 3px;
  padding-bottom: 6px;
  margin-top: -37px;
  $self: &;

  &__item {
    flex: 0 0 $builderWidth;
    transition: 400ms;

    &--hidden {
      opacity: 0;
    }
    &--shown {
      opacity: 1;
    }
  }

  &__header {
    font-size: 1.25em;
    color: $blue-gray;
    margin-bottom: 10px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 430px);
    gap: 8px;
  }
}

.campaign-tile {
  position: relative;
  padding: 19px 45px;
  box-sizing: border-box;
  background-color: white;
  transition: 200ms;
  border-radius: $border-radius;

  &:not(:disabled):hover {
    box-shadow: $btn-box-shadow;
  }

  &--notificationCard {
    margin-top: 0;
    padding: 11px 45px 11px 30px;
  }

  &__label {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 3px 8px;
    background-color: #fff;
    font-size: 13px;
    color: $info;
    border-radius: $border-radius;

    &--gray {
      background-color: $blue-gray;
      color: #fff;
    }
  }

  &__plus-icon {
    font-size: 70px;
  }

  &__details {
    margin: 0 28px;

  }
  &__name, &__description {
    color: $pulsate-blue;
  }

  &__name {
    flex: 1;
    text-align: left;
    font-size: 1.35em;
    line-height: 1.375;
    white-space: pre;
  }

  &__description {
    font-size: 1em;
    margin-top: 5px;
  }

  &__new {
    margin-right: 14px;
    padding: 5px 8px;
    display: inline-block;
    background-color: $info;
    border-radius: $border-radius;
    font-size: 14px;
    color: #fff;
    vertical-align: middle;
    text-transform: uppercase;
  }
}

.campaign-subtile {
  transition: 200ms;
  background-color: white;
  border-radius: $border-radius;
  padding: 19px 0;
  margin-bottom: 10px;

  &[type='button'] {
    -webkit-appearance: none;
  }

  &:hover {
    box-shadow: $btn-box-shadow;
    background-color: white;
  }
}

.info-box {
  padding: 10px 14px;
  background-color: #d8eaf6;
  box-sizing: border-box;
  color: $primary;
  font-size: 12px;
  border-radius: 5px;
}


.register-interest-modal {
  .modal-dialog {
    max-width: 620px;
  }

  .modal-content {
    overflow: hidden;
  }

  &__media {
    margin-left: -25px;
    margin-top: -87px;
  }

  .close {
    position: relative;
    z-index: 10;
  }
}

@include media-breakpoint-up(xxl) {
  .builder {

    &__header {
      margin-bottom: 18px;
    }
  }

  .builder__grid {
    gap: 25px;
  }

  .campaign-subtile {
    margin-bottom: 25px;
  }

  .campaign-tile {
    padding: 25px 35px;

    &--notificationCard {
      padding: 18px 35px 18px 25px;
    }
  }
}

@include media-breakpoint-up(xxxl) {
  .builder {
    margin-top: 0;
  }

  .campaign-tile {
    padding: 35px 45px;

    &--notificationCard {
      padding: 26px 45px 26px 30px;
    }
  }

  .campaign-subtile {
    padding: 35px 0;
  }
}
