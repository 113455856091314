@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .navbar-builder-status {
    display: flex;
    align-items: center;

    .progress-bar {
      transition: none;
    }

    &--in-progress {
      .progress-bar {
        transition: width 0.6s ease;
      }
    }

    &__status-pill {
      position: relative;
      margin-left: 15px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.badge-white {
        background-color: white;
      }

      .progress {
        background-color: transparent;
      }
    }

    &__save-progress {
      height: 100%;
      width: 100%;
      border-radius: 27px;
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &__label {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__name {
      font-size: 18px;
      color: white;
    }

    &__exit {
      opacity: 0.7;
      transition: 200ms;

      &:focus {
        outline: 0;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
