@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .select-with-search {
    &__dropdown-toggle {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__select-placeholder {
      color: $input-placeholder-color;
      font-weight: $input-placeholder-font-weight;
    }

    &--transparent {
      background-color: transparent;
      border: 0;
      height: 20px;

      .select-with-search {
        &__dropdown-toggle {
          padding-left: 0.25rem;
          padding-bottom: 0;
          padding-top: 0;
          background-color: transparent;
          width: 100%;
        }
      }
    }

    &__dropdown-menu--up {
      top: auto !important;
      bottom: calc(100% + 50px) !important;
    }
  }
}
