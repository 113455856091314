@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .opportunities-dashboard-page {
    --bs-tooltip-bg: red !important;
    --bs-border-color: #dde4f0;
    --bs-body-color-rgb: 0, 0, 0;
    --bs-h1-color: #000000;
    --bs-card-border-radius: 16px !important;

    color: #333333;

    .text-muted {
      color: rgba(#333333, 0.5) !important;
    }

    .table {
      --bs-table-color: #333333 !important;
      border-color: #f0f3f7;
      font-size: 18px !important;

      th {
        border: 0;
        font-size: 18px;
      }

      td,
      th {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .btn {
      --bs-btn-disabled-opacity: 0.5;
      --bs-btn-font-weight: 600;

      &:not(.dropdown-toggle) {
        --bs-btn-padding-y: 12px;

        &:hover {
          box-shadow: 0 4px 12px 0 rgba(51, 51, 51, 0.5);
        }
      }

      &-success {
        background-color: #5ABF9D;
        border-color: #5ABF9D;

        &:hover {
          background-color: #5ABF9D;
          border-color: #5ABF9D;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-weight: 800;
      color: #333333;
    }

    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 24px;
    }

    .card {
      box-shadow: 2px 2px 16px 0 #0000000d;
      --bs-card-border-radius: 16px;
    }

    .rounded-lg {
      border-radius: 12px;
    }

    .shadow-md {
      box-shadow: $box-shadow-md;
    }

    .opportunity {
      &-total {
        display: flex;
        align-items: center;
        background-color: #f1f1f1;
        border-radius: 16px;
        font-size: 18px;

        &__number {
          margin-left: 10px;
          font-weight: 800;
          font-size: 32px;
        }
      }

      &-col {
        flex: 1 1 auto;
        max-width: 50%;
        min-width: 450px;

        @media all and (min-width: 1900px) {
          flex: 0 1 calc(33.3333% - 1.5rem);
          max-width: 33.3333%;
        }
      }

      &-template {
        background-color: $catskill-white;
        flex-wrap: nowrap;
        box-shadow: none;

        &--missed {
          background-color: $catskill-white;
        }

        &--recommended {
          background-color: #f0e7f1;
        }
      }

      &-members {
        background-color: rgba(#fff, 0.6);
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;

        &__number {
          font-weight: 800;
          font-size: 36px;
        }
      }
    }

    .area-charts-row {
      row-gap: 1.5rem;
    }
  }
}
