@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.target-wrapper {
  min-width: 200px;
  display: flex;
  flex-direction: column;

  > * + * {
    border-top: 1px solid $mystic-gray;
  }
}

:global {
  .target-table {
    min-height: 200px;

    @include media-breakpoint-up(xl) {
      max-height: calc(100vh - 560px);
    }
  }

  .sticky {
    position: sticky;
    top: 10px;
  }
}
