@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .campaign-email-template-builder {
    height: 100%;

    .unlayer-editor {
      margin-left: -0.75rem;
      margin-right: -0.75rem;

      &__header {
        padding-bottom: 20px;
      }
    }
  }
}
