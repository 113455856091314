@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #eUqPylMA6Je1 {
    pointer-events: all
  }

  #eUqPylMA6Je15 {
    animation: eUqPylMA6Je15_c_o 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je18_to {
    animation: eUqPylMA6Je18_to__to 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je18 {
    animation: eUqPylMA6Je18_c_o 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je25_tr {
    animation: eUqPylMA6Je25_tr__tr 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je26 {
    animation: eUqPylMA6Je26_c_o 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je29_to {
    animation: eUqPylMA6Je29_to__to 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je39 {
    animation: eUqPylMA6Je39_c_o 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je39_to {
    animation: eUqPylMA6Je39_to__to 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je34 {
    animation: eUqPylMA6Je34_c_o 3000ms linear infinite normal forwards
  }

  #eUqPylMA6Je34_to {
    animation: eUqPylMA6Je34_to__to 3000ms linear infinite normal forwards
  }
}

@keyframes :global(eUqPylMA6Je15_c_o) {
  0% {
    opacity: 1
  }
  26.666667% {
    opacity: 1
  }
  33.333333% {
    opacity: 0.3
  }
  100% {
    opacity: 0.3
  }
}

@keyframes :global(eUqPylMA6Je18_to__to) {
  0% {
    transform: translate(50.75px, 78.5px)
  }
  10% {
    transform: translate(50.75px, 58.5px)
  }
  100% {
    transform: translate(50.75px, 58.5px)
  }
}

@keyframes :global(eUqPylMA6Je18_c_o) {
  0% {
    opacity: 0
  }
  10% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(eUqPylMA6Je25_tr__tr) {
  0% {
    transform: translate(51px, 85px) rotate(0deg)
  }
  13.333333% {
    transform: translate(51px, 85px) rotate(0deg)
  }
  16.666667% {
    transform: translate(51px, 85px) rotate(5deg)
  }
  20% {
    transform: translate(51px, 85px) rotate(-5deg)
  }
  23.333333% {
    transform: translate(51px, 85px) rotate(0deg)
  }
  100% {
    transform: translate(51px, 85px) rotate(0deg)
  }
}

@keyframes :global(eUqPylMA6Je26_c_o) {
  0% {
    opacity: 0
  }
  26.666667% {
    opacity: 0
  }
  33.333333% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(eUqPylMA6Je29_to__to) {
  0% {
    transform: translate(36px, 53.764773px)
  }
  40% {
    transform: translate(36px, 53.764773px)
  }
  50% {
    transform: translate(36px, 96.764773px)
  }
  100% {
    transform: translate(36px, 96.764773px)
  }
}

@keyframes :global(eUqPylMA6Je34_to__to) {
  0% {
    transform: translate(36px, 4.764773px)
  }
  40% {
    transform: translate(36px, 4.764773px)
  }
  50% {
    transform: translate(36px, 53.764773px)
  }
  100% {
    transform: translate(36px, 53.764773px)
  }
}


@keyframes :global(eUqPylMA6Je34_c_o) {
  0% {
    opacity: 0
  }
  39.333333% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}


@keyframes :global(eUqPylMA6Je39_to__to) {
  0% {
    transform: translate(36px, 97.176598px)
  }
  40% {
    transform: translate(36px, 97.176598px)
  }
  50% {
    transform: translate(36px, 137.176598px)
  }
  100% {
    transform: translate(36px, 137.176598px)
  }
}

@keyframes :global(eUqPylMA6Je39_c_o) {
  0% {
    opacity: 1
  }
  40% {
    opacity: 1
  }
  46.666667% {
    opacity: 0.14
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
}