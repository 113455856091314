@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .range-slider {
    &__thumb {
      height: 19px;
      width: 32px;
      border-radius: 3px;
      background-color: #C050BA;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(40,81,140,0.5);
    }

    &__track-wrapper {
      height: 36px;
      display: flex;
      width: 100%;
    }

    &__track {
      height: 7px;
      width: 100%;
      border-radius: 4px;
      align-self: center;
    }

    &__thumb-line {
      height: 7px;
      width: 2px;
      margin: 1px;
    }
  }
}