@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .section {
    width: 100%;

    &__arrow {
      position: relative;
      margin: -16px auto auto auto;
      display: block;
      width: 80px;
      height: 40px;
      border: 1px solid $mystic-gray;
      border-top: 0;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      background-color: $alabaster;

      &::before {
        content: '';
        width: 19px;
        height: 22px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../../../../components/icons/IconArrowDown.svg);
        background-size: 19px 22px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    // &__card {
    //   overflow: auto;
    // }
  }
}
