@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.progress-wrapper {
  position: relative;
  height: 29px;
}

.progress-label {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  height: 100%;
  width: 100%;
  border-radius: 27px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: $silver-gray;
}

.with-progress {
  padding: 0;
}