@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .masonry {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    width: 100%;
    margin: auto;

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: stretch;
      flex-grow: 1;
    }

    img {
      width: 100%;
    }
  }
}