.modal-header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  align-items: center;
  border-bottom: 0;
}

.modal-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.modal-footer {
  border-top: 0;
}

.modal-title {
  font-size: 24px;
}
