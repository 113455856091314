@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #animated-icon {
    pointer-events: all
  }
}

:global {
  #animatedObject_to {
    animation: animatedObject_to__to 2000ms linear infinite normal forwards
  }

  #animatedObject_tr {
    animation: animatedObject_tr__tr 2000ms linear infinite normal forwards
  }

  #animatedObject {
    animation: animatedObject_c_o 2000ms linear infinite normal forwards
  }
}

@keyframes :global(animatedObject_to__to) {
  0% {
    offset-distance: 0%
  }
  25% {
    offset-distance: 100%
  }
  100% {
    offset-distance: 100%
  }
}

@keyframes :global(animatedObject_tr__tr) {
  0% {
    transform: rotate(0deg)
  }
  37.5% {
    transform: rotate(0deg)
  }
  45% {
    transform: rotate(20deg)
  }
  55% {
    transform: rotate(-20deg)
  }
  65% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(0deg)
  }
}



@keyframes :global(animatedObject_c_o) {
  0% {
    opacity: 0
  }
  15% {
    opacity: 0.04
  }
  25% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}