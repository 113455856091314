// Please keep this file in sync with frontend/src/utils/color.js
// Pretty color names from http://chir.ag/projects/name-that-color

$body-color: #28518c;

$primary: #3e95d1;
$secondary: #9f72c7;
$tertiary: #669ae5;
$success: #28a745;
$info: #5abf9d;
$warning: #f9861c;
$danger: #ee2650;
// $danger-light: rgba($danger, 0.8);
$light: #e9ecef;
$dark: #153d77;
$yellow: #e9c441;

$alabaster: #f9f9f9;
$gray: #adb5bd;
$link-water-gray: #f4f7fc;
$botticelli: #d6dfeb;
$silver-gray: #bababa;
$blue-gray: #93a8c5;
$mystic-gray: #d9dfe8;
$pigeon-post: #b7c6da;
$nepal: #8fa4c3;
$concrete: #f2f2f2;
$light-grayish-blue: #e8ebf3;
$border: #ddd;
$white-lilac: #e6e5f5;
$text: #383838;
$catskill-white: #eef2f5;

$pulsate-blue: #28518c;
$light-pulsate-blue: rgba($pulsate-blue, 0.5);
$bali-hai-blue: #7f95b3;
$bali-hai: #879bb7;

$fuchsia-pink: #c050ba;
$azure: #0e7afe;
