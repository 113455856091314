@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .campaign-header {
    border-top: 1px solid $mystic-gray;
    padding: 1em 0;
    font-size: 16px;
    line-height: 24px;

    &__target {
      font-size: 24px;
      font-weight: 600;
      color: $pulsate-blue;
      text-align: center;

      &--small {
        font-size: 16px;
        margin-bottom: 5px;
        text-align: left;
        font-weight: 400;
      }
    }

    &__col {
      flex: 0 0 33.3%;
    }
  }
}
