@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.message-dropdown {
  cursor: pointer;

  &:after {
    height: auto;
  }
}
