@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.status-column {
  margin-right: 10px; // add space between status and groups column on 1280px
}

.pause-resume-wrapper {
  height: 100%;
  width: 27px;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0,0,0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0,0,0, 0.3);
  }

  svg {
    color: white;
    margin-right: 2px;
  }
}