@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.upload-input-wrapper {
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  font-size: 0;
  z-index: -1;

  &:focus {
    outline: none;
  }
}

.file-name-input-wrapper {
  width: 100%;
  margin-bottom: 0;

  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: 11.5px;
  }
}

.upload-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
  min-width: 110px;
  margin-top: 30px;
}

.upload-button-label {
  position: absolute;
}

.upload-button-error {
  border: 1px solid red;
  border-right: 0;
  height: 39px;
}

.file-name-input-error {
  input {
    border-left: 0;
  }
}
