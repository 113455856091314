@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .deliver {
    &__limits-container {
      width: 520px;
    }

    &__limits-left-block {
      min-width: 100px;
    }

    &__times {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__time {
      padding-left: 90px;

      &__header {
        font-size: 16px;
      }

      &__sub-header {
        margin-top: 5px;
      }

      &:not(:first-child) {
        border-top: 1px solid $mystic-gray;
      }
    }

    &__footer {
      width: 100%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $alabaster;
      border: 1px solid $bali-hai-blue;
      height: 50px;
      justify-content: center;
      border-top: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      margin: 0 auto;
    }
  }
}
