.dropdown-menu.show {
  animation-name: dropdownAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  z-index: 1031;
}

.dropdown-menu.show.opacity-animation {
  animation-name: dropdownOpacityAnimation;
}

.dropdown-menu.show.dropdown-nav {
  animation-name: dropdownAnimationNav;
}

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }

  to {
    opacity: 1;
    transform: translateY(40px);
  }
}

@keyframes dropdownAnimationNav {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }

  to {
    opacity: 1;
    transform: translateY(57px);
  }
}

@keyframes dropdownOpacityAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dropdown-item {
  &:active {
    background-color: $link-water-gray;
  }
}

.dropdown {
  &-menu {
    top: 0;
  }


  &-toggle {
    display: flex;
    align-items: center;
    padding-right: 0.4rem;

    &:after {
      transition: 300ms;
      border: none;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath fill='%2328518c' d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' %3E%3C/path%3E%3C/svg%3E");
      width: 20px;
      height: 20px;
    }

    &--right {
      position: relative;

      &::after {
        margin-left: auto;
      }
    }

    &--invert::after {
      transform: rotate(180deg) translateY(-1px);
    }
  }

  .show > &-toggle:after {
    transform: rotate(180deg);
  }

  .show > &-toggle--invert:after {
    transform: rotate(0deg);
  }

  &__submenu {
    position: relative;

    .dropdown-menu {
      left: 100% !important;
      top: -48.5px !important;
    }
  }

  &--theme-modern {
    &.disabled-field {
      .dropdown-toggle {
        background-color: white !important;
      }
    }

    .dropdown-toggle {
      border: 1px solid #DDE4F0;
      border-radius: 30px;
      padding: 10px 25px;

      &::after {
        content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47607 14.3188C9.65602 14.4988 9.94395 14.4988 10.1239 14.3188L15.465 8.97772C15.645 8.79776 15.645 8.50983 15.465 8.32988L14.2701 7.13497C14.0902 6.95501 13.8022 6.95501 13.6223 7.13497L9.79998 10.9572L5.97771 7.13497C5.79776 6.95501 5.50983 6.95501 5.32988 7.13497L4.13497 8.32988C3.95501 8.50983 3.95501 8.79776 4.13497 8.97772L9.47607 14.3188Z' fill='%23333333'/%3E%3C/svg%3E%0A");
      }

      &.disabled {
        border: 1px solid #DDE4F0 !important;

        &::after {
          opacity: 0.5;
        }
      }
    }

    .dropdown-menu {
      --bs-dropdown-bg: white;
      --bs-dropdown-border-radius: 20px;
      --bs-dropdown-border-width: 0;
      --bs-dropdown-min-width: 105px;
      --bs-dropdown-item-padding-y: 14px;
      box-shadow: 2px 2px 16px 0 #3E95D180;
    }

    .dropdown-item {
      color: black !important;
      font-size: 16px;
      &:active {
        background-color: $link-water-gray;
      }
    }
  }
}