@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.show-more-btn {
  color: $blue-gray;
  font-size: 14px;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 6px;
    border-left: 6px solid $blue-gray;
    border-bottom: 5px solid #fff;
    border-top: 5px solid #fff;
    border-radius: 2px;
    vertical-align: initial;
    transition: 200ms;
  }

  &--active {
    &:before {
      transform: rotate(90deg);
    }
  }
}

.auto-segments {
  padding-top: 5px;
  padding-left: 15px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 3px;
    top: 0;
    bottom: 0.87em;
    display: block;
    width: 1px;
    background-color: $blue-gray;
    transform: translateX(0.2px);
  }

  &__item {
    position: relative;
    line-height: 1.2;
    &:before {
      content: '';
      position: absolute;
      left: -11px;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: 6px;
      background-color: $blue-gray;
    }
  }
}
