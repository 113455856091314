@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .toolbar {
    padding: 25px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 20;

    &__search-input {
      min-width: 265px;
    }

    @include media-breakpoint-up(xxxl) {
      .places-select {
        min-width: 360px;
      }
    }
  }

  .places-select {
    .form-control {
      border-right: 0;
      &:focus {
        box-shadow: none;
        border-color: $input-border-color;
      }
    }

    .input-group-text {
      border-left: 0;
      position: relative;

      &:after {
        content: '';
        width: 2px;
        height: 17px;
        position: absolute;
        left: 0;
        background: $mystic-gray;
      }
    }
  }
}