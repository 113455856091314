@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.messages-wrapper {
  overflow-y: auto;
  height: calc(100vh - 345px);
  margin-top: 10px;
}

.chat-wrapper {
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 241px);
  display: flex;
}

.chat-message {
  background-color: #E8ECEE;
  white-space: pre-line;
}

.filters-wrapper {
  padding: 0 17px;
}