@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.errors-container {
  overflow-y: auto;
  max-height: 180px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding-left: 17px;
}

.error-item {
  font-size: 18px;
  color: $danger;
}

