@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .send-test-email-modal {
    .custom-select {
      &__control {
        padding: 5px 0;
      }

      &__multi-value {
        &__label {
          padding: 7px 3px 7px 10px;
          font-size: 95%;
        }

        &__remove {
          padding-left: 8px;
          padding-right: 8px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
