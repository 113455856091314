@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
$label-height: 40px;
$wrapper-height: 40px;

.radio-button-toolbar {
  display: flex;
  align-items: center;
  background-color: rgba($blue-gray, 0.6);
  border-radius: 3px;
  position: relative;
  border: 1px solid transparent;
  box-sizing: border-box;

  .radio-button {
    padding-left: 0;
    color: white;
  }

  .checked-indicator {
    background-color: white;
    position: absolute;
    width: 124px;
    height: $label-height;
    transition: all 0.3s;
    border: 1px solid $bali-hai-blue;
    border-radius: 3px;
  }

  .selected-first,
  .selected-second {
    box-shadow: 2px 0 4px rgba(40, 81, 140, 0.25);
  }

  label {
    cursor: pointer;
    height: $wrapper-height;
    padding: 0 30px;
    display: flex;
    align-items: center;
    margin: 0;
    transition: color 0.3s;

    &:hover {
      transition: color 0.3s;
      color: $pulsate-blue;
    }

    span {
      position: relative;
      z-index: 9;
      font-weight: 400;
      margin: 0 auto;
    }
  }

  input {
    opacity: 0;
    position: fixed;
    width: 0;

    &:checked + label span {
      transition: color 0.3s;
      color: $dark;
    }
  }
}

.divider {
  width: 1px;
  height: 20px;
  // background-color: white;
}
