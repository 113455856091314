@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .account-settings-personal {
   .upload-image__form {
     margin-left: 5px;
   }
  }
}

.admin-avatar-wrapper {
  padding-top: 70px;
  position: relative;
}