@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #animatedInAppSmallIcon {
    pointer-events: all
  }

  #animateInAppSmalldObject1_to {
    animation: animateInAppSmalldObject1_to__to 2800ms linear infinite normal forwards
  }

  #animateInAppSmalldObject2_to {
    animation: animateInAppSmalldObject2_to__to 2800ms linear infinite normal forwards
  }

  #animateInAppSmalldObject1_ts {
    animation: animateInAppSmalldObject1_ts__ts 2800ms linear infinite normal forwards
  }

  #animateInAppSmalldObject3 {
    animation: animateInAppSmalldObject3_c_o 2800ms linear infinite normal forwards
  }

  #animateInAppSmalldObject1 {
    animation: animateInAppSmalldObject1_c_o 2800ms linear infinite normal forwards
  }
}


@keyframes :global(animateInAppSmalldObject1_to__to) {
  0% {
    offset-distance: 0%
  }
  35.714286% {
    offset-distance: 100%
  }
  100% {
    offset-distance: 100%
  }
}

@keyframes :global(animateInAppSmalldObject1_ts__ts) {
  0% {
    transform: rotate(-180deg) scale(-0.3, 0.3)
  }
  35.714286% {
    transform: rotate(-180deg) scale(-1, 1)
  }
  100% {
    transform: rotate(-180deg) scale(-1, 1)
  }
}

@keyframes :global(animateInAppSmalldObject1_c_o) {
  0% {
    opacity: 0
  }
  17.857143% {
    opacity: 0.1
  }
  25% {
    opacity: 0.2
  }
  35.714286% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animateInAppSmalldObject3_c_o) {
  0% {
    opacity: 0
  }
  35.714286% {
    opacity: 0
  }
  53.571429% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animateInAppSmalldObject2_to__to) {
  0% {
    transform: translate(176.095215px, 114.911999px)
  }
  57.142857% {
    transform: translate(176.095215px, 114.911999px)
  }
  64.285714% {
    transform: translate(178.095215px, 114.911999px)
  }
  71.428571% {
    transform: translate(176.095215px, 114.911999px)
  }
  78.571429% {
    transform: translate(178.095215px, 114.911999px)
  }
  85.714286% {
    transform: translate(176.095215px, 114.911999px)
  }
  92.857143% {
    transform: translate(178.095215px, 114.911999px)
  }
  100% {
    transform: translate(176.095215px, 114.911999px)
  }
}