@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .unlayer-editor {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header-title {
      flex: 0 0 auto;
    }

    &__plugin {
      flex: 1 1 auto;
    }
  }
}
