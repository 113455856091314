@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  $stepContainerOffsetTop: 217px;
  $bottomActionsHeight: 55px;
  $stepOffsetTop: 80px;

  .step-content-wrapper {
    padding-top: 1rem;
    max-width: 1800px;

    // @include media-breakpoint-up('xxl') {
    // }

    @media all and (min-width: 1500px) {
      padding-top: 4.5rem;
    }

    &--full {
      margin-top: 0;
      padding-top: 0;
      width: 100%;
      max-width: 100%;

      @media all and (min-height: 760px) {
        height: calc(100vh - #{$bottomActionsHeight} - #{$stepOffsetTop});
      }
    }
  }

  .campaign-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 1.5rem;
    background-color: $botticelli;
    transition: 300ms ease-in-out;
    z-index: 9;

    &--shrink {
      left: 250px;
    }
  }
}
