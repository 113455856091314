@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .campaigns-groups-multi-select {
    .nav-tabs {
      border-bottom: 0;
    }

    .dropdown-menu {
      padding-bottom: 0;

      &.show {
        padding-top: 0;
        width: 300px;
      }
    }

    &__selected-separator {
      width: 100%;
      height: 2px;
      background-color: $mystic-gray;
      margin: 10px 0 12px;
    }
  }
}