.o-0 {
  opacity: 0;
}
.o-25 {
  opacity: 0.25;
}
.o-30 {
  opacity: 0.3;
}
.o-50 {
  opacity: 0.5;
}
.o-75 {
  opacity: 0.75;
}
.o-100 {
  opacity: 1;
}

.start-0 {
  left: 0;
}

.end-0 {
  right: 0;
}

.transition-all {
  transition: all 0.3s;
}

.border-bottom-transparent {
  border-bottom-color: transparent !important;
}

.border-radius-default {
  border-radius: $border-radius;
}

.bold {
  font-weight: 700;
}

.px-15p {
  padding-left: 15px;
  padding-right: 15px;
}

.px-20p {
  padding-left: 20px;
  padding-right: 20px;
}

.no-wrap {
  white-space: nowrap;
}

.column {
  column-rule: 2px solid $mystic-gray;

  &-3 {
    column-count: 3;
    column-gap: 9%;
  }

  &--custom-border {
    column-rule: none;
    column-gap: 0;
  }

  &__item {
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */
  }

  &--custom-border &__item {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 26px;
    margin-right: 20px;

    @include media-breakpoint-up(xxxl) {
      margin-right: calc(20% - 0.75rem);
      padding-right: calc(20% - 0.75rem);
    }

    &--border {
      @include media-breakpoint-up(xxxl) {
        border-right: 2px solid $mystic-gray;
      }
    }
  }
}

.counter {
  counter-reset: counter;

  &__item:before {
    counter-increment: counter;
    content: counter(counter);
  }
}

.bg-water-gray {
  background-color: $link-water-gray;
}

.bg-concrete {
  background-color: $concrete;

  &:hover,
  &:active,
  &:focus {
    background-color: $concrete;
  }
}

.bg-body-main {
  background-color: $body-color;
}

.bg-white-lilac {
  background-color: $white-lilac;
}

.bg-fuchsia-pink {
  background-color: $fuchsia-pink;
}

.bg-blue-gray {
  background-color: $blue-gray;
}

.color-primary {
  color: $primary;
}

.color-secondary {
  color: $secondary;
}

.color-danger {
  color: $danger;
}

.color-white {
  color: white;
}

.color-body {
  color: $body-color;
}

.color-subheader {
  color: $bali-hai;
}

.color-yellow {
  color: $yellow;
}

.color-silver-gray {
  color: $silver-gray;
}

.color-fuchsia-pink {
  color: $fuchsia-pink;
}

.pointer-none {
  pointer-events: none;
}

.w-fit-content {
  width: fit-content;
}

// Remove after upgrading to bootstrap 5.3
.fw-medium {
  font-weight: 500;
}

.no-decoration:hover {
  text-decoration: none;
}

.remove-icon {
  width: 16px;
  height: 16px;
  stroke-width: 4px;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-0-1 {
  flex: 0 1;
}

.flex-1-1 {
  flex: 1 1;
}

.flex-1-1-3 {
  flex: 1 1 33.333%;
}

.flex-0-1-3 {
  flex: 0 1 33.333%;
}

.fill-current {
  fill: currentColor;
}

.pe-auto {
  pointer-events: auto !important;
}

.pe-none {
  pointer-events: none !important;
}

//Styling based on parent state
.group {
  &:hover {
    .group-hover\:block {
      display: block !important;
    }

    .group-hover\:hidden {
      display: none !important;
    }
  }
}

.z-index-20 {
  z-index: 20;
}

.w-85 {
  width: 85%;
}