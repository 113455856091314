@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .preview-tag-wrapper {
    &::before {
      content: '';
    }
    &::after {
      content: '';
    }
  }

  $bottom-space: 62px;

  .textarea {
    border-bottom: $bottom-space solid #fff;
    margin-right: -0.7rem;
    padding-right: 5px;
    height: fit-content;
    min-height: calc(7.5em + #{$bottom-space});
    max-height: 28vh;
    overflow-y: auto;
    box-sizing: border-box;
    cursor: text;

    &--without-line-breaks br {
      display: none;
    }

    &__input {
      // width: 100%;
      // min-height: 7.5em;
      white-space: pre-wrap;
      word-break: break-word;

      &:focus {
        outline: 0;
      }
    }

    &__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      color: $blue-gray;
    }

    &__characters {
      display: inline-block;
      min-width: 17px;
      text-align: right;
    }

    &-extras {
      position: absolute;
      // left: 0;
      right: 0;
      bottom: 16px;
      top: auto;
    }

    &--small {
      height: 7em;
      min-height: auto;
      border-bottom-width: 1em;
    }

    &--small &__input {
      min-height: 6em;
    }

    &--disabled {
      padding: 5px;
      margin-right: 0;
      min-height: 7.5em;
      border-bottom: none;
      border: 1px solid #f5f7fb;
    }
  }

  //! using sibling selector inside :global makes error
  .textarea--small + .textarea-extras {
    opacity: 0;
    visibility: hidden;
    transition: 200ms;
  }

  .textarea--small:hover + .textarea-extras,
  .textarea-extras:hover {
    opacity: 1;
    visibility: visible;
  }
}
