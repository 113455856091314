@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.background {
  background: $link-water-gray;
}

.background-gray {
    background: #F8F8F8;
}

:global {
  .navbar-container {
    justify-content: space-between;
  }
  .navbar-3-columns {
    .navbar-dropdowns {
      flex-grow: unset;
    }
  }

  .no-space {
    margin: 0;
    padding: 0;
  }

  .desktop-wider-space {
    @media all and (min-width: 2200px) {
      padding: 0 5rem;
    }
  }

  .pem-alert {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
    font-size: 1.1rem;
    background: $danger;
    z-index: 8;

    &--journeys {
      top: 90px;
      bottom: unset;
      max-width: 445px;
    }
  }
}
