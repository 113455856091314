@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
@mixin resolution($r) {
  @media (min-width: #{$r}) {
    @content;
  }
}

.fixed-height {
  @include resolution(550px) {
    height: 235px;
  }

  @include resolution(1300px) {
    height: 180px;
  }

  height: auto;
}

.question-circle {
  margin-left: 10px;
}

.carrot-indicator {
  margin-left: 10px;
  margin-right: 10px;
}
