@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .email-image-upload {
    $self: &;

    .tab {
      .nav-tabs {
        .nav-link {
          color: $body-color;
          cursor: pointer;
          font-size: 16px;

          &.active {
            font-weight: 500;
          }

          svg {
            margin-top: -2px;
          }
        }
      }
    }

    &__upload-btn {
      margin-top: -10px;
    }

    .modal-content {
      background-color: $link-water-gray;
    }

    &__search {
      max-width: 250px;
    }

    &__body {
      margin-top: -40px;
    }

    &__images-container {
      height: 490px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;

      &--busy {
        overflow: hidden;
      }
    }

    &__no-images-info,
    &__loading {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__name,
    &__allowed-extensions {
      font-size: 12px;
    }

    &__media-card {
      &:hover {
        #{ $self }__delete {
          svg {
            opacity: 0.5;
          }
        }
      }
    }

    &__media {
      max-width: 100%;
      max-height: 100%;
    }

    &__media-container {
      cursor: pointer;
      background-color: #e7e7e7;
      width: 100%;
      height: 175px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__delete {
      position: absolute;
      z-index: 10;
      top: 0px;
      right: 8px;
      font-size: 25px;

      svg {
        transition: opacity 0.3s;
        opacity: 0;
      }
    }

    &__busy-indicator {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255, 0.5);
      z-index: 10;
    }
  }
}
