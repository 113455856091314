@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.search-input {
  min-width: 240px;
  max-height: 40px;
  padding-left: 18px;
  padding-right: 18px;
  background: #fff;
  overflow: hidden;

  input {
    margin-top: -1px;
    margin-left: 14px !important; // override input group margin
  }
}

.focus-border {
  box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
}
