@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .crop {
    width: 640px;

    &-wrapper {
      margin: 0 auto;
      width: fit-content;
    }
  }
}
