@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.container {
  margin: 0 auto;
  max-width: 1800px;
  width: 100%;
}

.text-container {
  margin: 0 auto;
  max-width: 830px;
  text-align: center;
}

.teaser {
  border: 1px dashed $blue-gray;
  border-radius: 5px;

  &__col {
    flex: 0 1 50%;
  }

  &__description {
    font-size: 26px;
    line-height: 1.5;
    font-weight: 300;

    @include media-breakpoint-up('xxl') {
      font-size: 32px;
    }
  }

  &__description-col {
    padding-left: 7%;
  }

  &__diagram {
    padding-top: 6.6%;
    min-height: 450px;
    max-height: 750px;
    height: calc(100vh - 128px);
  }

  &__btn {
    padding: 15px 30px;
    font-size: 16px;
    @include media-breakpoint-up('xxl') {
      padding: 18px 30px;
    }
  }
}

.about {
  margin-top: 10vh;

  &__header {
    margin-bottom: 0.75em !important;
    color: $fuchsia-pink !important;
    font-size: 30px !important;
    font-weight: 600 !important;

    @include media-breakpoint-up('xxl') {
      font-size: 36px !important;
    }
  }

  &__cards {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
  }

  &__cards-item {
    background-color: #fff;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &__cards-item-content {
    padding: 30px;
    box-sizing: border-box;
  }

  &__card-header {
    font-size: 20px !important;
    @include media-breakpoint-up('xxl') {
      font-size: 22px !important;
    }
  }
  &__card-subheader {
    margin: 1.1em 0 !important;
    font-size: 16px !important;
  }
  &__card-text {
    @include media-breakpoint-up('xxl') {
      font-size: 16px !important;
    }
  }

  &__icon-box {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 150px;
    background-color: $blue-gray;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    box-sizing: border-box;

    @include media-breakpoint-up('xxl') {
      height: 180px;
    }
  }

  &__icon {
    max-height: 44%;
    max-width: 86px;
    display: inline-block;
  }
}

.cta {
  margin: 30px 0 60px 0;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $pulsate-blue;
  color: #fff;
  border-radius: 12px;

  @include media-breakpoint-up('xxl') {
    height: 550px;
  }

  &__header {
    margin-bottom: 0.75em !important;
    color: #fff !important;
    font-size: 30px !important;
    font-weight: 600 !important;

    @include media-breakpoint-up('xxl') {
      font-size: 36px !important;
    }
  }

  &__description {
    font-size: 18px;
  }

  &__btn {
    position: relative;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 100px;
    background-image: linear-gradient(
      90deg,
      #3e95d1 0%,
      #6643b4 28.65%,
      #c050ba 77.08%,
      #f78493 100%
    );
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border: none;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(#000, 0.25);
    transition: 200ms;
  }

  &__strap {
    position: absolute;
    display: block;
    background-color: rgba(#fff, 0.1);
    transform: rotate(-56deg);

    &:nth-child(1) {
      left: 62%;
      top: 0;
      width: 230px;
      height: 115px;
      transition: 370ms ease-in-out;
    }
    &:nth-child(2) {
      left: -11%;
      top: -15%;
      width: 230px;
      height: 135px;
      transition: 400ms ease-in-out;
    }
    &:nth-child(3) {
      left: -19%;
      top: 0;
      width: 230px;
      height: 40px;
      transition: 320ms ease-in-out;
    }
    &:nth-child(4) {
      left: 62%;
      top: 0;
      width: 230px;
      height: 115px;
      transition: 470ms ease-in-out;
      transform: translateX(-400%) rotate(-56deg);
    }
    &:nth-child(5) {
      left: -11%;
      top: -15%;
      width: 230px;
      height: 135px;
      transition: 300ms ease-in-out;
      transform: translateX(200%) rotate(-56deg);
    }
    &:nth-child(6) {
      left: -19%;
      top: 0;
      width: 230px;
      height: 40px;
      transition: 420ms ease-in-out;
      transform: translateX(300%) rotate(-56deg);
    }
  }

  &__btn:hover &__strap {
    &:nth-child(1) {
      transform: translateX(-300%) rotate(-56deg);
    }
    &:nth-child(2) {
      transform: translateX(230%) rotate(-56deg);
    }
    &:nth-child(3) {
      transform: translateX(400%) rotate(-56deg);
    }
    &:nth-child(4) {
      transform: rotate(-56deg);
    }
    &:nth-child(5) {
      transform: rotate(-56deg);
    }
    &:nth-child(6) {
      transform: rotate(-56deg);
    }
  }
}
