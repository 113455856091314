@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.swatch-color {
  width: 28px;
  height: 28px;
  margin-bottom: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &--white {
    border: 1px solid #C0C0C0;

    svg {
      fill: $body-color;
    }
  }

  &--white-with-border {
    border: 1px solid #C0C0C0;
  }
}

.color-picker-toggle {
  &:active {
    border-color: transparent !important;
  }
}

.hex {
  width: 95px;
}

.rgbInput {
  width: 55px;
}

.dropdown {
  width: 350px;
}