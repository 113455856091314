@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.rule-dropdown {

  &--join {
    min-width: auto;
  }

  &--special {
    background-color: $body-color;

    &:hover {
      background-color: var(--bs-btn-hover-bg);
    }
  }
}

.rule-input {
  max-width: 160px;
  border-color: $body-color;
}

.rule-submenu {
  inset: 0;
  background-color: $link-water-gray;
}

.rule-group {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: $fuchsia-pink;
  }
}

.rule-group-item {
  position: relative;
  gap: 10px;

  &:not(:only-child) {
    padding-left: 20px;
  }

  &--hover {
    // transition: background-color 200ms;

    &:hover {
      // background-color: darken($link-water-gray, 2%);

      .rule-delete {
        opacity: 1;
      }
    }
  }
}

.rule-delete {
  position: absolute;
  top: 0.5em;
  right: 0;
  opacity: 0;
  transition: 200ms;
}

.rule-map {
  width: 100%;
  height: 400px;
}
