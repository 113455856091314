@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.switch {
  top: 0;
  left: 100%;
}

.desktop-box {
  max-width: 350px;
}
