@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.notification {
  position: relative;
  margin-bottom: 15px;
  padding: 10px 15px 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: grab;
  background-color: #fff;
}

.notificationDrag {
  margin-left: 10px;
  z-index: 1;
  display: inline-block;
  height: 18px;
  width: 11px;
  background-image: radial-gradient($bali-hai-blue 35%, transparent 50%);
  background-position: 0 0;
  background-size: 5px 6px;
}

.notificationPosition {
  margin: 0 23px 0 10px;
  display: inline-block;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border: 2px solid $blue-gray;
  background-color: $blue-gray;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 36px;
}
