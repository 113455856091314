@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .apex-area-chart-tooltip {
    $self: &;

    padding: 5px 10px;
    background-color: white;
    border: 1px solid $secondary;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &__label {
      font-weight: 500;
      color:  black;
      font-size: 14px;
      margin-top: 2px;
    }

    &__value {
      font-weight: 600;
      color: $fuchsia-pink;
      font-size: 18px;
      margin-left: 7.5px;
    }

    &--theme-green {
      border: 1px solid #5ABF9D;

      #{ $self }__value {
        color: #5ABF9D;
      }
    }
  }
}