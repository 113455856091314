@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.grid-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-column-gap: 25px;
  grid-row-gap: 25px;
}

:global {
  .template-preview-modal {
    .modal-dialog {
      max-width: 750px;
    }
    .modal-content {
      background-color: transparent;

      .modal-header {
        display: none;
      }
    }
  }
}
