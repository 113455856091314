@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .learn-more-widget {
    background-color: #E2EAFE;

    &__text {
      color: #8196B2;
      font-weight: 500;
      text-align: center;
    }

    &__btn {
      border: 1px solid $blue-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $body-color;
      border-radius: 3px;
      font-weight: 500;
      font-size: 16px;
      max-width: 255px;
    }

    &__media {
      max-height: 270px;
    }
  }
}