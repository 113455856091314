@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .geofences-list {
    min-width: 360px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;

    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;

      &--selected {
        background-color: $link-water-gray;
      }

      &__name,
      &__groups {
        word-break: normal;
      }

      &__shape {
        width: 16px;
      }

      &__name {
        font-size: 16px;
      }

      &__groups {
        color: $blue-gray;
        width: 100%;
        margin-top: 5px;
        text-align: left;
      }

      &__form-error {
        font-size: 12px;
        text-align: left;
      }
    }

    .table {
      word-break: break-word;

      &__row {
        &--selected {
          background-color: $link-water-gray;
        }

        .geofences-list-item__actions {
          opacity: 0;
          transition: opacity 0.3s;
          display: flex;

          a {
            border: 0;
            background: transparent;
          }
        }
        &:hover {
          .geofences-list-item__actions {
            opacity: 1;
          }
        }
      }

      thead {
        position: sticky;
        top: 0;
        background-color: $blue-gray;
        color: white;
        z-index: 10;
      }

      tr {
        padding: 1em;
      }

      &__col {
        border-top: 1px solid $mystic-gray;
        padding: 1.5em 0.5em 1.5em 0;
        height: 88px;

        &--select {
          padding-left: 1em !important;
        }

        &--select,
        &--header {
          height: unset;
        }

        &-geofence-name {
          width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: normal;
        }
      }
    }
  }
}