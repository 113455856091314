@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.template-icon {
  width: 150px;
  height: 150px;
  background-color: $link-water-gray;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &--center {
    margin: 15px auto 0 auto;
  }

  &--small {
    width: 75px;
    height: 75px;
    flex: 0 0 75px;
  }

  &__img {
    width: 75%;
    height: 75%;
  }
}
