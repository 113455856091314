@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .engagement-summary-carousel {
    background-color: #eef2f7;
    border: 0;
    min-height: 223px;
    height: 100%;

    .slide {
      height: 100%;
      width: 100%;

      img {
        max-width: 138px;
      }

      p {
        line-height: 31px;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      top: auto;
      bottom: 15px;
      position: absolute;
      left: unset;
      opacity: 1;
      width: 37px;
      height: 37px;

      .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.283667 9.03584L7.71365 16.5951C7.96398 16.8498 8.36452 16.8498 8.61486 16.5951L10.2771 14.904C10.5274 14.6493 10.5274 14.2418 10.2771 13.9871L4.95995 8.5774L10.2771 3.16773C10.5274 2.91304 10.5274 2.50553 10.2771 2.25084L8.61486 0.559683C8.36452 0.304991 7.96399 0.304991 7.71365 0.559683L0.283667 8.11895C0.0333317 8.37364 0.0333316 8.78115 0.283667 9.03584Z' fill='%23C050BA'/%3E%3Cpath d='M0.283667 9.03584L7.71365 16.5951C7.96398 16.8498 8.36452 16.8498 8.61486 16.5951L10.2771 14.904C10.5274 14.6493 10.5274 14.2418 10.2771 13.9871L4.95995 8.5774L10.2771 3.16773C10.5274 2.91304 10.5274 2.50553 10.2771 2.25084L8.61486 0.559683C8.36452 0.304991 7.96399 0.304991 7.71365 0.559683L0.283667 8.11895C0.0333317 8.37364 0.0333316 8.78115 0.283667 9.03584Z' stroke='%23C050BA'/%3E%3C/svg%3E%0A");
      }

      .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7163 8.11943L3.28635 0.56016C3.03602 0.305468 2.63548 0.305468 2.38514 0.56016L0.722908 2.25132C0.472572 2.50601 0.472572 2.91352 0.722908 3.16821L6.04005 8.57788L0.722908 13.9875C0.472572 14.2422 0.472572 14.6497 0.722908 14.9044L2.38514 16.5956C2.63548 16.8503 3.03602 16.8503 3.28635 16.5956L10.7163 9.03632C10.9667 8.78163 10.9667 8.37412 10.7163 8.11943Z' fill='%23C050BA'/%3E%3Cpath d='M10.7163 8.11943L3.28635 0.56016C3.03602 0.305468 2.63548 0.305468 2.38514 0.56016L0.722908 2.25132C0.472572 2.50601 0.472572 2.91352 0.722908 3.16821L6.04005 8.57788L0.722908 13.9875C0.472572 14.2422 0.472572 14.6497 0.722908 14.9044L2.38514 16.5956C2.63548 16.8503 3.03602 16.8503 3.28635 16.5956L10.7163 9.03632C10.9667 8.78163 10.9667 8.37412 10.7163 8.11943Z' stroke='%23C050BA'/%3E%3C/svg%3E%0A");
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 11px;
        height: 17px;
      }
    }

    .carousel-control-prev:hover .carousel-control-prev-icon,
    .carousel-control-next:hover .carousel-control-next-icon{
      background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='19.4214' cy='18.5777' r='18.5777' fill='%23C050BA'/%3E%3Cpath d='M25.7124 18.1175L18.2824 10.5582C18.0321 10.3035 17.6316 10.3035 17.3812 10.5582L15.719 12.2494C15.4687 12.5041 15.4687 12.9116 15.719 13.1663L21.0361 18.5759L15.719 23.9856C15.4687 24.2403 15.4687 24.6478 15.719 24.9025L17.3812 26.5936C17.6316 26.8483 18.0321 26.8483 18.2824 26.5936L25.7124 19.0344C25.9628 18.7797 25.9628 18.3722 25.7124 18.1175Z' fill='white'/%3E%3Cpath d='M25.7124 18.1175L18.2824 10.5582C18.0321 10.3035 17.6316 10.3035 17.3812 10.5582L15.719 12.2494C15.4687 12.5041 15.4687 12.9116 15.719 13.1663L21.0361 18.5759L15.719 23.9856C15.4687 24.2403 15.4687 24.6478 15.719 24.9025L17.3812 26.5936C17.6316 26.8483 18.0321 26.8483 18.2824 26.5936L25.7124 19.0344C25.9628 18.7797 25.9628 18.3722 25.7124 18.1175Z' stroke='white'/%3E%3C/svg%3E%0A");
      width: 37px;
      height: 37px;
    }

    .carousel-control-prev {
      right: 55px;

      &:hover {
        .carousel-control-prev-icon {
          rotate: 180deg;
        }
      }
    }

    .carousel-control-next {
      right: 5px;
    }
  }
}
