@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .flow-node-icon {
    .transparent-background {
      fill: $link-water-gray;
    }

    &--filled {
      .custom-fill {
        fill: $body-color;
      }

      .custom-stroke {
        stroke: $body-color;
      }
    }

    &--filled-notification {
      .custom-fill {
        fill: $fuchsia-pink;
      }

      .custom-stroke {
        stroke: $fuchsia-pink;
      }
    }
  }
}
