@import 'theme_customizations';


textarea {
  @include placeholder {
    color: $blue-gray;
  }
}

.form-group-padding {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.form-group {
  label {
    font-size: 14px;
  }

  .error-message {
    display: none;
  }

  &--error {
    label {
      color: $red;
    }

    .form-control {
      border-color: red !important;
      border-width: 1px !important;

      &:-moz-placeholder            { color: $red; } // Firefox 4-18
      &::-moz-placeholder           { color: $red; } // Firefox 19+
      &:-ms-input-placeholder       { color: $red; } // Internet- Explorer 10+
      &::-webkit-input-placeholder  { color: $red; } // Safari and- Chrome

      &:focus {
        box-shadow: none;
      }
    }

    .error-message {
      display: block;
      color: $red;
    }

  }
}

// !nested styles, requires workarounds
.disabled-field,
.disabled-field > * {
  background-color: #f5f7fb !important;
  opacity: 1 !important;
  color: darken(#e9ecef, 30) !important;
}

.disabled-opacity {
  opacity: 0.65;
}

.error {
  &-label {
    color: $red !important;
  }

  &-input {
    //! nested styles overrides this
    border-color: $red !important;

    &::before {
      border-color: $red !important;
    }
  }
}

.form-control-number {
  width: 70px;
}

.form-control[type='file'] {
  overflow: hidden; // prevent pseudo element button overlap

  &:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  @extend .form-control;

  &::file-selector-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    background-color: $primary;
    color: white;
    pointer-events: none;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: $input-border-width;
    border-radius: 0;
    transition: all 0.3s;
  }

  &:hover, &:focus {
    &::file-selector-button {
      background-color: darken($primary, 10%) !important;
    }
  }
}

.form-control:disabled,
.form-control-file:disabled,
.form-control[readonly],
.form-control-file[readonly] {
  background-color: #f5f7fb !important;
  border: 1px solid darken(#e9ecef, 30);
  opacity: 1 !important;
  color: darken(#e9ecef, 30) !important;
}

.form-general-error {
  font-size: 13px;
  line-height: 18px;
  color: $red;
}