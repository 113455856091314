// Datepicker
.react-datepicker {
  font-family: $headings-font-family;
  border: 1px solid #28518c80;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  &__day--disabled {
    opacity: 0.5;
  }

  &__day-name,
  &__day,
  &__time-name {
    color: $pulsate-blue;
    margin: 15px;
    font-size: 16px;

    &.react-datepicker__day--outside-month,
    &.react-datepicker__day--disabled {
      color: $blue-gray;
    }
  }

  &__header {
    background-color: $link-water-gray;
    border-bottom: 1px solid $blue-gray;
    margin: 0 20px;

    &__dropdown {
      padding-bottom: 20px;
    }

    &:not(.react-datepicker__header--has-time-select) {
      margin: 0;
    }
  }

  &__month-year-dropdown {
    z-index: 10;
  }

  &__month-year-read-view--selected-month-year {
    color: $pulsate-blue;
    font-weight: 500;
    font-size: 16px;
  }

  &__month-year-read-view--down-arrow {
    width: 15px;
    height: 8px;
    border: none;
    right: -25px;
    top: 8px;
    background: url(~/assets/img/chevron-down.svg) no-repeat;
    outline: none;
    transform: rotate(0);
  }

  &__day-names {
    border-top: 1px solid $blue-gray;
  }

  &__current-month {
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    color: $fuchsia-pink;
  }

  &__navigation {
    width: 38px;
    height: 38px;
    border: none;
    top: 27px;
    background: url(~/assets/img/datepicker-chevron-right-circle.svg) no-repeat;
    outline: none;

    &:focus {
      outline: none;
    }

    &--next {
      right: 40px;
    }

    &--previous {
      transform: rotate(180deg);
      left: 40px;
    }

    &-icon {
      display: none;
    }
  }

  &__triangle {
    border-top-color: $link-water-gray !important;
  }

  &__day--outside-month {
    visibility: hidden;
  }

  &__day--in-range {
    position: relative;
    background-color: transparent;
    &::after {
      content: '';
      position: absolute;
      right: -15px;
      left: -15px;
      top: -10px;
      bottom: -10px;
      background-color: rgba($primary, 0.2);
    }
    &:hover {
      background-color: transparent;
    }
  }

  &__day--in-selecting-range {
    position: relative;
    background: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  &__day--keyboard-selected,
  &__day--outside-month {
    background-color: transparent;
  }

  &__day--selecting-range-end,
  &__day--selecting-range-start {
    &:before {
      content: '';
      position: absolute;
      left: -10px;
      top: -10px;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      background-color: transparent;
      border: 1px solid $pulsate-blue;
    }
  }

  &__day--selected,
  &__day--range-end,
  &__day--range-start {
    color: white;
    background-color: transparent;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: -10px;
      width: 48px;
      height: 48px;
      background: $primary;
      border-radius: 100%;
      z-index: -1;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      color: #fff;
    }
  }

  &__day--range-end,
  &__day--range-start {
    &:after {
      content: '';
      position: absolute;
      right: 50%;
      left: -15px;
      top: -10px;
      bottom: -10px;
      background: rgba($primary, 0.2);
      z-index: -1;
    }
  }
  &__day--range-end {
    &:after {
      right: 50%;
      left: -15px;
    }
  }
  &__day--range-start {
    &:after {
      left: 50%;
      right: -15px;
    }
  }

  &__day--range-start#{&}__day--range-end:after {
    display: none;
  }

  &__month--selecting-range &__day--in-range {
    background: transparent;
    color: $pulsate-blue;
  }
  &__month--selecting-range &__day--range-end,
  &__month--selecting-range &__day--range-start {
    color: #fff;
    &::before {
      background-color: $primary;
    }
  }

  &-popper {
    z-index: 10 !important;
  }

  &__month-container {
    background-color: $link-water-gray;
  }

  &--time-only {
    .react-datepicker {
      &__header {
        .react-datepicker-time__header {
          color: $pulsate-blue;
          font-weight: 500;
        }
      }

      &__time {
        &-list-item {
          color: $pulsate-blue;
          font-weight: 500;
        }
      }
    }
  }

  &__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }
}
