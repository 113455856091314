@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .subject-input {
    .input-group-prepend {
      .form-control {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .btn {
          opacity: 1;
        }
      }
    }
  }
}
