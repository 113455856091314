@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
$fa-font-path: '@fortawesome/fontawesome-free/webfonts/';

@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-image-crop/dist/ReactCrop.css';
@import 'reactflow/dist/style.css';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import 'colors';
@import 'fonts';
@import 'components/datepicker';
@import 'old_rails_fonts';
@import 'svg_url';
@import 'theme_customizations';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

$custom-colors: (
  'gray': #adb5bd,
  'gray-light': #d2cece,
  'yellow-light': #F7D83C,
  'fire':  #9D2F02,
  'mine-shaft': #333333,
  'catskill-white': $catskill-white,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@import '../../vendor/spark/assets/scss/app';

:root {
  --bs-border-color-translucent: transparent;
}

.table {
  --bs-table-striped-bg: $link-water-gray;
}

@import 'reset';
@import 'icons';
@import 'text';
@import 'buttons';
@import 'forms';
@import 'badges';
@import 'borders';
@import 'dropdowns';
@import 'notification';
@import 'utilities';
@import 'tabs';
@import 'web_sdk';
@import 'components/components';
@import 'components/tooltip';
@import 'components/modal';
@import 'components/reports';
@import 'components/alerts';
@import 'components/campaign_preview';
@import 'components/campaign_builder';
@import 'components/custom-select';
@import 'components/charts';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  // background: transparent;
  background: $link-water-gray;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $light-pulsate-blue;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($light-pulsate-blue, 30);
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.page-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

// Cookie consent plugin override
.cc_div {
  font-family: $headings-font-family;
  #c-ttl {
    color: $body-color;
  }
  #c-p-bn,
  #s-all-bn {
    background: $primary !important;
  }
  #c-s-bn,
  #s-sv-bn,
  #s-rall-bn {
    background: transparent;
    color: $body-color;
    &:hover {
      background: transparent !important;
    }
  }
  .cc-link {
    color: $body-color !important;
  }
}

// Recaptcha badge
.grecaptcha-badge {
  visibility: hidden;
}

.spark-root {
  font-family: $font-family-base;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left; // 3
  background-color: $body-bg; // 2

  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }

  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  .header {
    position: relative;
    margin-bottom: ($spacer * 3);
  }

  .header-title {
    font-size: $h2-font-size;
    color: $header-color;
  }

  .header-subtitle {
    font-size: $h5-font-size;
    color: $header-color;
  }

  .text-sm {
    font-size: $font-size-sm;
  }

  .text-lg {
    font-size: $font-size-lg;
  }

  b,
  strong {
    font-weight: $font-weight-bold;
  }

  pre.snippet {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
  }

  .display-5 {
    @include font-size($display5-size);
    font-weight: $display5-weight;
    line-height: $display-line-height;
  }

  .card {
    box-shadow: $card-shadow;
    margin-bottom: ($spacer * 1.5);
  }

  a.card {
    cursor: pointer;
    text-decoration: none;
  }

  .card-header {
    background: transparent;
  }

  .card-body {
    padding-top: ($spacer * 0.75);
  }

  // .card-title {
  //   color: $body-color;
  //   font-size: $font-size-base;
  //   line-height: 1.5;

  // }

  .card-subtitle {
    font-weight: $font-weight-normal;
  }

  .card-table {
    margin-bottom: 0;
  }

  .card-table tr td:first-child,
  .card-table tr th:first-child {
    padding-left: $card-spacer-x;
  }

  .card-table tr td:last-child,
  .card-table tr th:last-child {
    padding-right: $card-spacer-x;
  }

  .card-img,
  .card-img-top,
  .card-img-bottom {
    @include img-fluid;

    @media all and (-ms-high-contrast: none) {
      height: 100%;
    }
  }

  .card-actions {
    a {
      color: $body-color;
      text-decoration: none;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    .dropdown {
      line-height: 1.4;
    }
  }

  .card-img-hover {
    transition: all 0.1s ease-in-out;

    &:hover {
      transform: scale(1.035);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
  }

  h1,
  .h1 {
    @include font-size($h1-font-size);
  }
  h2,
  .h2 {
    @include font-size($h2-font-size);
  }
  h3,
  .h3 {
    @include font-size($h3-font-size);
  }
  h4,
  .h4 {
    @include font-size($h4-font-size);
  }
  h5,
  .h5 {
    @include font-size($h5-font-size);
  }
  h6,
  .h6 {
    @include font-size($h6-font-size);
  }

  hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
  }

  //
  // Emphasis
  //

  small,
  .small {
    @include font-size($small-font-size);
    font-weight: $font-weight-normal;
  }

  mark,
  .mark {
    padding: $mark-padding;
    background-color: $mark-bg;
  }
}

@mixin badge-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  @at-root .spark-root a#{&} {
    @include hover-focus() {
      color: color-yiq($bg);
      background-color: darken($bg, 10%);
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 $badge-focus-width rgba($bg, 0.5);
    }
  }
}
