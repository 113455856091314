.btn-body {
  @include button-variant(
    $body-color,
    darken($body-color, 7.5%),
    darken($body-color, 10%),
    lighten($body-color, 5%),
    lighten($body-color, 10%),
    darken($body-color, 30%)
  );

  color: #fff;

  &:hover, &:focus-visible {
    color: #fff;
  }
}

.btn-outline-body {
  @include button-outline-variant(
    $body-color,
    $pulsate-blue,
    white,
    $body-color,
    $body-color
  );
}

.btn-outline-gray {
  color: $gray;
  border-color: $gray;
}

.btn-outline-fuschia {
  @include button-outline-variant(
                  $fuchsia-pink,
                  $fuchsia-pink,
                  rgba(255,255,255, 0),
                  $fuchsia-pink
  );
}

// .btn-group.show .dropdown-toggle::after {
//   transform: rotate(225deg);
// }

.btn-no-outline:focus {
  outline: none;
  box-shadow: none;
}

.btn-hover-shadow {
  &:hover {
    box-shadow: $btn-box-shadow;
  }
}

.btn {
  max-height: 100%;

  &--hover {
    transition: box-shadow 0.3s;

    &:not(.disabled):not(:disabled):hover {
      box-shadow: $btn-box-shadow;
    }
  }

  &--wide {
    padding: 0.7rem 1.95rem;
  }

  &--transparent {
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &--hover-primary:not(.disabled):not(:disabled):hover {
    //! lots of bootstrap and importants
    color: $primary !important;
  }

  &--error,
  &--error.disabled {
    border: 2px solid $danger;
  }

  &.disabled {
    pointer-events: initial;
  }

  &.disabled:not(.btn-outline-body) {
    border: 0;
  }
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #2d82bc;
  border-color: #2d82bc;
}
