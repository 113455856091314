@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.stat-icon {
  height: 160px;
  width: 160px;

  &--user {
    background-position: bottom;
  }

  &--location {
    width: 250px;
  }
}

.stat {
  &__title {
    font-size: 36px;
  }
}

.diff {
  font-size: 14px;

  &__positive {
    font-weight: 600;
    color: $info;

    &::after {
      content: '';
      margin: 0 4px 0 2px;
      display: inline-block;
      border-bottom: 0.8em solid $info;
      border-left: 0.5em solid transparent;
      border-right: 0.5em solid transparent;
    }
  }

  &__negative {
    font-weight: 600;
    color: $danger;

    &::after {
      content: '';
      margin: 0 4px 0 2px;
      display: inline-block;
      border-top: 0.8em solid $danger;
      border-left: 0.5em solid transparent;
      border-right: 0.5em solid transparent;
    }
  }
}

.tableWrapper {
    overflow-y: auto;
    height: 365px
}
