@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .add-geofences-modal {
    .modal-dialog {
      max-width: 925px;
    }
    &__small-col {
      max-width: 15px;
    }
  }
}