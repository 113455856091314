@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.bar-secondary {
  background-color: #e2eafe;
}

.bar-secondary__title {
  font-weight: 500;
  font-size: 20px;
  color: $blue-gray;
}

.bar-secondary__subtitle {
  font-size: 11px;
}

@include media-breakpoint-up(xxl) {
  .bar-secondary__title {
    font-size: 24px;
  }
}

@include media-breakpoint-up(xl) {
  .bar-secondary__subtitle {
    font-size: 13px;
  }
}

@include media-breakpoint-up(xxl) {
  .bar-secondary__subtitle {
    font-size: 14px;
  }
}

.learn-more-button {
  color: $body-color;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid $body-color;
  transition: box-shadow 0.3s;
  padding: 20px 40px;
  white-space: nowrap;

  &__icon {
    margin-right: 15px;
  }

  &:hover {
    transition: box-shadow 0.3s;
    box-shadow: $btn-box-shadow;
    border: 1px solid $body-color;
    color: $body-color;
  }

  &--purple {
    background-color: #a46cc4;
    color: white;
    border: 1px solid white;

    &:hover {
      box-shadow: 0 4px 4px rgba($body-color, 0.5);
      border: 1px solid white;
      color: white;
    }
  }

  @include media-breakpoint-up(xxxl) {
    padding: 20px 55px;
  }
}
