@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .beacons-battery-status-dot {
    border-radius: 100%;
    display: block;
    width: 26px;
    height: 26px;

    &--slim {
      width: 16px;
      height: 16px;
    }

  }
}