@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .giphy-wrapper {
    width: 100%;

    &--active {
      width: calc(100% - 0.5rem - 18px);
    }

    &__close {
      position: absolute;
      right: 0;
      top: 8px;
    }

    input {
      display: block;
      width: 100%;
      height: calc(2.3125rem + 2px);
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #153d77;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #7f95b3;
      border-radius: 3px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
      input {
        transition: none;
      }
    }

    input::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    input:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #153d77;
    }

    input:focus {
      color: #153d77;
      background-color: #fff;
      border-color: #a5ceea;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(62, 149, 209, 0.25);
    }

    input:-ms-input-placeholder {
      color: rgba(40, 81, 140, 0.5);
      opacity: 1;
    }

    input::placeholder {
      color: rgba(40, 81, 140, 0.5);
      opacity: 1;
    }

    input:disabled,
    input[readonly] {
      background-color: #e9ecef;
      opacity: 1;
    }
  }

  .giphy-selected {
    inset: 0;
    background-color: rgba(#000, 0.7);

    &__btn {
      max-width: 50%;
    }

    &__img {
      width: 100%;
      height: auto;
    }
  }
}
