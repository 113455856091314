@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .template-loading-indicator {
    padding: 1em;
    width: 140px;
    text-align: center;
    border: 1px solid #7f95b3;
  }
}
