@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .table__row {
    .row-actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      opacity: 0;

      &__item {
        &:hover {
          background-color: blue;
          border-radius: 100%;
        }
      }
    }

    &:hover {
      .row-actions {
        opacity: 1;
      }
    }
  }
}
