@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .template {
    $self: &;

    cursor: pointer;
    transition: 0.3s opacity;

    &:hover:not(.template--disabled) {
      #{ $self }__body {
        box-shadow: $btn-box-shadow;
      }

      #{ $self }__toolbar {
        opacity: 1;
      }
    }

    &__body {
      position: relative;
      width: 100%;
      border-radius: 3px;
      transition: 0.1s;
      overflow: hidden;
    }

    &__media {
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }

    &__name {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: $pulsate-blue;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &__plus-icon {
      position: absolute;
      top: 35%;
      align-self: center;
    }

    &--blank &__body {
      color: $blue-gray;
      justify-content: center;
      display: flex;
    }

    &--blank &__toolbar {
      justify-content: flex-end;
    }

    &--active {
      #{ $self }__name {
        font-weight: 600;
        color: $info;
      }

      #{ $self }__body .preview-device__box {
        background-color: $info;
      }

      &:hover {
        #{ $self }__toolbar {
          background-color: rgba($info, 0.7);
        }
      }
    }

    &--disabled {
      opacity: 0.5;
    }

    &__toolbar {
      width: 100%;
      height: 100%;
      background-color: rgba($pulsate-blue, 0.7);
      position: absolute;
      overflow: hidden;
      display: flex;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.3s, background-color 0.3s;
      justify-content: space-between;
      flex-direction: column;
    }

    &__top-actions-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 100%;
    }

    &__action {
      padding: 8px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border: 1px solid $bali-hai-blue;
      border-radius: 3px;
      text-align: center;
      color: $pulsate-blue;
      transition: opacity 0.3s, box-shadow 0.3s;

      &:focus {
        color: $pulsate-blue;
        box-shadow: none;
      }

      &--select-template {
        width: auto !important;
        height: auto;

        &:hover:not(:disabled):not(.disabled) {
          color: $pulsate-blue;
        }
      }
    }

    &__action-content {
      opacity: 0.7;
      transition: 200ms;
      color: $pulsate-blue;
    }

    &__action:hover &__action-content {
      opacity: 1;
    }
  }
  .disabled .template__action-content {
    opacity: 1;
  }
}
