@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.banner {
  position: absolute;
  right: 60px;
  bottom: 60px;
  width: 400px;

  @media (min-height: 1075px) {
    position: static;
    margin-top: 15px;
    width: 411px;
  }

  &__img {
    width: 100%;
    line-height: 0;
    display: block;
  }

  &__content {
    margin-top: -2px;
    padding-top: 10px;
    background: linear-gradient(
      270deg,
      #28518c 0%,
      #28518c 43.72%,
      #6643b4 100%
    );
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
  }

  &__text-box {
    padding: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-bottom: 0;
    background: linear-gradient(90deg, #6643b4 0%, #28518c 100%);
    background-size: 100%;
    background-clip: text;
    font-weight: 600;
    font-size: 18px;
    -webkit-text-fill-color: transparent;
  }

  &__btn {
    padding: 22px 29px;
    background-color: $fuchsia-pink;
    border-radius: 18px;
    border: 0;
    font-size: 16px;
    outline: none;
    &:hover {
      background-color: darken($fuchsia-pink, 10%);
    }
  }
}
