.icon {
  margin: 0;
  width: 18px;
  height: 18px;
  display: inline-block;
  background: center / contain no-repeat;

  &--inactive {
    opacity: 0.5;
  }

  &--big {
    height: 63px;
    width: 83px;
  }

  &--huge {
    height: 140px;
    width: 120px;
  }

  &--med {
    height: 26px;
    width: 26px;
  }

  &--small {
    height: 15px;
    width: 15px;
  }

  &--enter {
    background-image: url(../../components/icons/entering.svg);
  }

  &--exit {
    background-image: url(../../components/icons/leaving.svg);
  }

  &--dwell {
    background-image: url(../../components/icons/dwelling.svg);
  }

  &--close {
    background-image: url(../../components/icons/close.svg);
  }

  &--unsubscribe {
    background-image: url(../../components/icons/unsubscribe.svg);
  }

  &--control-group {
    background-image: url(../../components/icons/control_group.svg);
  }

  &--sent {
    background-image: url(../../components/icons/sent.svg);
  }

  &--pan {
    background-image: url(../../components/icons/pan.svg);
  }

  &--reset-zoom {
    background-image: url(../../components/icons/reset_zoom.svg);
  }

  &--zoom-in {
    background-image: url(../../components/icons/zoom_in.svg);
  }

  &--zoom-out {
    background-image: url(../../components/icons/zoom_out.svg);
  }

  &--select {
    background-image: url(../../components/icons/select.svg);
  }

  &--emojineutral {
    background-image: url(../img/serious.png);
  }

  &--emojidislike {
    background-image: url(../img/sad.png);
  }

  &--emojidownvote {
    background-image: url(../img/thumbsdown.png);
  }

  &--emojiupvote {
    background-image: url(../img/thumbsup.png);
  }

  &--emojilike {
    background-image: url(../img/happy.png);
  }

  &--ios-white {
    background-image: url(../../components/icons/ios_logo_inactive.svg);
  }

  &--ios-blue {
    background-image: url(../../components/icons/ios_logo_active.svg);
  }

  &--watch-white {
    background-image: url(../../components/icons/ios_watch_inactive.svg);
  }

  &--watch-blue {
    background-image: url(../../components/icons/ios_watch_active.svg);
  }

  &--android-white {
    background-image: url(../../components/icons/android_logo_inactive.svg);
  }

  &--android-blue {
    background-image: url(../../components/icons/android_logo_active.svg);
  }

  &--emoji {
    background-image: url(../../components/icons/emoji.svg);
    background-color: #fff;
  }

  &--variants {
    background-image: url(../../components/icons/variants.svg);
  }

  &--notes {
    background-image: url(../../components/icons/notes.svg);
  }

  &--envelope {
    background-image: url(../../components/icons/envelope.svg);
  }

  &--rocket {
    background-image: url(../../components/icons/rocket.svg);
  }

  &--arrow-switch {
    background-image: url(../../components/icons/switch_arrows.svg);
  }

  &--audiences {
    background-image: url(../../pages/Dashboard/UI/icons/audiences.svg);
  }

  &--new-users {
    background-image: url(../../pages/Dashboard/UI/icons/new_users.svg);
  }

  &--in-active-users {
    background-image: url(../../pages/Dashboard/UI/icons/inactive_users.svg);
  }

  &--push-permissions {
    background-image: url(../../pages/Dashboard/UI/icons/push-permissions.svg);
  }

  &--location-permissions {
    background-image: url(../../pages/Dashboard/UI/icons/location_permission.svg);
  }
}
