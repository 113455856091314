// !important to override css modules
.custom-select {
  $self: &;

  &__control {
    border-color: $light-pulsate-blue !important;
    min-height: 40.5px !important;
    border-radius: $border-radius !important;
    .form-group--error & {
      border-color: red !important;
    }

    &--menu-is-open {
      #{ $self }__indicator {
        svg {
          transform: rotate(180deg) translateY(-1px);
        }
      }
    }
  }

  &__placeholder {
    color: $light-pulsate-blue !important;
  }

  &__multi-value {
    background: linear-gradient(
      270deg,
      rgba($secondary, 0.2) 0%,
      rgba($blue-gray, 0.2) 100%
    ) !important;

    &__label {
      border-bottom-left-radius: 6px !important;
      border-top-left-radius: 6px !important;
      color: $body-color !important;
    }

    &__remove {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
      cursor: pointer;

      &:hover {
        background-color: $pulsate-blue !important;

        svg {
          color: white;
        }
      }
    }
  }

  &__single-value,
  &__input {
    color: $pulsate-blue !important;
  }

  &__menu {
    background-color: $link-water-gray !important;
    border: 1px solid $light-pulsate-blue !important;
    z-index: 10 !important;
  }

  &__indicator {
    svg {
      transition: 0.3s;
      transform: translateY(-1px);
      fill: $pulsate-blue;
    }
  }
}
