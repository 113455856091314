@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .table {
    $self: &;

    th {
      font-size: 14px;
    }

    &__col {
      padding-top: 21px;
      padding-bottom: 21px;

      &--wide {
        max-width: 200px;
      }

      &--select {
        width: 15px;
      }

      &--groups-header {
        min-width: 240px;
      }
    }

    &__row {
      &:hover {
        background: $link-water-gray;
      }

      &--disabled {
        background-color: $link-water-gray;

        .groups-row,
        .select-row {
          pointer-events: none;
        }

        .row-actions {
          .btn {
            pointer-events: none;

            svg {
              color: $gray;
              pointer-events: none;
            }
          }
        }
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    &--last-right &__col:last-of-type {
      text-align: right;
    }

    &--big &__row {
      @include media-breakpoint-up('xxxl') {
        font-size: 16px;
      }
    }

    //! global importants
    &--small {
      padding: 0 !important;
    }

    &--small &__header-row {
      //! $gray-300
      border-bottom: 2px solid #dee2e6;
    }

    &--small &__col {
      font-size: 14px;
      line-height: 23px;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      padding-left: 0 !important;
      border: 0 !important;
    }

    &--small &__col--header {
      padding-bottom: 8px !important;
    }

    &--small &__row:first-child &__col {
      padding-top: 8px !important;
    }

    &--overflow {
      flex: 1;
      overflow: auto;
    }

    &--overflow & {
      table-layout: fixed;

      &__col--select {
        width: 50px;
      }
    }

    //! global importants - to refactor
    &--overflow &__col:first-child {
      padding-left: 20px !important;
    }

    &--overflow &__col:last-child {
      padding-right: 20px !important;
    }

    &.table-striped {
      #{$self}__row {
        &:hover {
          background-color: darken($link-water-gray, 2%);
        }
      }
    }

    &--sticky-header {
      thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
      }
    }

    .react-loading-skeleton {
      margin: 8px 0;
      min-width: 15px;
    }

    @include media-breakpoint-down('xxxl') {
      .icon-button {
        width: 25px;
        height: 25px;

        svg {
          padding: 3px;
        }
      }
    }
  }

  .sort-container {
    &__icon {
      color: $bali-hai-blue;
      transform: translateY(-50%);

      &:first-child {
        transform: translateY(50%);
      }

      &--active {
        color: $body-color;
      }
    }

    &--inverted &__icon {
      color: unset;

      &--active {
        color: $body-color;
      }
    }
  }

  .table-striped tbody tr:nth-of-type(2n) {
    background-color: $link-water-gray;
  }
}

.scrollableBody {
  overflow: auto;

  table {
    border-collapse: collapse;
  }

  thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  &--blue {
    thead tr th {
      background-color: $link-water-gray;
    }
  }
}

.col {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #3e4676;
}

.col-wide {
  max-width: 200px;
  display: inline-block;
}

.col-name {
  min-width: 125px;

  @include media-breakpoint-up('xxxl') {
    min-width: 300px;
  }
}

.col-medium {
  max-width: 150px;
}

.break-line {
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table--auto-col {
  table-layout: auto;
}
