@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  $controls-space-laptop: 20px;
  $controls-space-desktop: 25px;

  .journey-builder {
    height: calc(100vh - 80px);
    width: 100%;
    overflow: hidden;
    $self: &;

    &__open-setup {
      position: absolute;
      top: $controls-space-laptop;
      left: 15px;
      z-index: 5;
    }

    .react-flow {
      &__container,
      &__pane,
      &__nodes {
        margin-top: 50px;
      }

      &__edges {
        z-index: 1 !important;
      }

      &__edge-text {
        font-size: 18px;
        fill: $blue-gray;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__controls {
        box-shadow: none;

        &-button {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border: 1px solid $body-color;
          border-radius: 3px;
        }
      }

      &__renderer:after {
        content: '';
        position: fixed;
        inset: 0;
        background-color: rgba($body-color, 0.75);
        pointer-events: none;
        opacity: 0;
        transition: 200ms;
      }

      &__node {
        transition: opacity 200ms;
      }
    }

    &--overlay {
      .react-flow {
        &__renderer:after {
          opacity: 1;
        }

        &__node.selected {
          opacity: 1;
        }

        &__controls {
          z-index: 3;
        }

        &__node {
          opacity: 0.2;
        }

        .add-new-block-edge {
          pointer-events: none;
          opacity: 0.2;
        }
      }

      #{$self}__open-setup {
        z-index: 3;
      }
    }

    &__btns {
      position: absolute;
      bottom: $controls-space-laptop;
      right: $controls-space-laptop;
      z-index: 5;
    }

    @include media-breakpoint-up(xxl) {
      .react-flow {
        &__controls {
          left: $controls-space-desktop;
          bottom: $controls-space-desktop;
        }
      }

      &__open-setup {
        top: $controls-space-desktop;
        left: $controls-space-desktop;
      }

      &__btns {
        right: $controls-space-desktop;
        bottom: $controls-space-desktop;
      }
    }

    &__bar {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: $controls-space-laptop;
      z-index: 1051;

      @include media-breakpoint-up(xxl) {
        padding: $controls-space-desktop;
      }
    }
  }

  @import './Nodes/Nodes.scss';
}
