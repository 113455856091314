@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .geofences-map {
      width: 70%;

      /*----------------------------------------------------------------------------
        Info window start
      ----------------------------------------------------------------------------*/
      .gm-style-iw-c {
        min-width: 343px !important;
      }

      // hide default close icon
      .gm-ui-hover-effect {
        display: none !important;
      }

      .info-window {
        padding: 1em;

        &__radius {
          .form-control {
            width: 80px;
            text-align: center;
          }
        }
      }
      /*----------------------------------------------------------------------------
        Info window end
      ----------------------------------------------------------------------------*/

      /*----------------------------------------------------------------------------
        Drawing controls start
      ----------------------------------------------------------------------------*/
      .map-controls {
        display: flex;
        box-shadow: 0 2px 6px rgba(40,81,140, .4);
        border-radius: 5px;
        background-color: white;
        margin-top: 10px;
        overflow: hidden;

        &--drawing {
          right: 72px !important;
        }

        &--full-screen {
          right: 10px !important;
        }

        &--zoom {
          right: 10px !important;
          bottom: 20px !important;
          display:block;
        }


        // hide default google maps hand tool
        &--full-screen + div {
          display: none;
        }
      }

      .map-control {
        cursor: pointer;
        text-align: center;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: all 0.3s;

        &:hover {
          background-color: $link-water-gray;
        }

        &--circle {
          border-right: 1px solid $mystic-gray;
        }

        &--polygon {
          margin-right: 0;
          border-right: 1px solid $mystic-gray;
        }

        &--reset-drawing {
        }

        &__inner {
          color: rgb(25,25,25);
          font-size: 16px;
          line-height: 38px;
          padding-left: 5px;
          padding-right: 5px;
        }

        &--full-screen {
          border-radius: 5px;
        }

        &--zoom-in {
          border-bottom: 1px solid $mystic-gray;
        }
      }

      .gmnoprint {
        box-shadow: 0 2px 6px rgba(40,81,140, .4);

        .gm-style-mtc {
          button {
            width: 100px !important;
            height: 50px !important;
            vertical-align: middle !important;
            line-height: 40px !important;
            text-align: center !important;
            display: flex !important;
            justify-content: center !important;
            box-shadow: none !important;
            align-items: center !important;
            color: $body-color !important;
            transition: all 0.3s;

            &:hover {
              background-color: $link-water-gray !important;
            }
          }

          &:nth-child(1) {
            button {
              border-top-left-radius: 5px !important;
              border-bottom-left-radius: 5px !important;
            }
          }

          &:nth-child(2) {
            button {
              border-left: 1px solid $mystic-gray !important;
              border-top-right-radius: 5px !important;
              border-bottom-right-radius: 5px !important;
            }
          }
        }
      }

      .gm-svpc {
        display: none !important;
      }

      &.drawing-mode-circle .map-control--circle,
      &.drawing-mode-polygon .map-control--polygon,
      &.drawing-mode-reset .map-control--reset-drawing{
          background-color: $link-water-gray;
      }

      /*----------------------------------------------------------------------------
        Drawing controls end
      ----------------------------------------------------------------------------*/

    @include media-breakpoint-up(xxl) {
        width: 80%;
    }

    @include media-breakpoint-up(xxxl) {
        width: 90%;
    }
  }
}