@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.btn {
  overflow: hidden;

  &__icon {
    padding: 5px;
  }

  &__text {
    max-width: 0.1px;
    width: 100%;
    white-space: nowrap;
    transition: 400ms ease-in-out;
    position: relative;
    line-height: 27px;
    right: -15px;

    &:after {
      content: ' ';
      padding-right: 12px;
    }
  }

  &:hover &__text {
    max-width: 300px;
    right: -5px;
  }

  &:hover {
    border: 1px solid $fuchsia-pink;
    color: $fuchsia-pink;
  }
}