.status-pill {
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
}

.download-btn {
  &:focus {
    box-shadow: 0 0 0 0.2rem #3e95d140;
  }
}

.reports {
  &-box {
    padding: 2em;
    min-height: 150px;

    &--higher {
      min-height: 220px;
    }
  }

  &--number &__title {
    font-size: 14px;
  }

  &--number &__tooltip {
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: 1;
  }

  &--number &__text {
    font-size: 36px;
  }

  &__progress-box {
    height: 24px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: rgba($blue-gray, 0.4);
  }

  &__progress-bar {
    position: relative;
    padding: 5px 0;
    background-color: $blue-gray;
    overflow: visible;

    &-value {
      position: absolute;
      right: 5px;
      font-size: 14px;
      font-weight: 600;

      &--sticky {
        left: 5px;
        right: auto;
      }
    }
  }
}

.summary-field {
  &__label {
    //! spark based on nested styles
    color: $blue-gray !important;
    font-size: 13px !important;
  }

  &__content {
    margin-bottom: 0;
    font-size: 1rem;
  }

  &--green &__content,
  &--red &__content {
    font-size: 24px;
    line-height: 1em;
  }

  &--green &__content {
    color: $info;
  }

  &--red &__content {
    color: $danger;
  }

  &--truncate &__content {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    cursor: pointer;
  }
}

.chart {
  margin: auto;
  position: relative;
  width: 100%;
  min-height: 300px;
  overflow-x: hidden;

  &-xs {
    min-height: 200px;
  }

  &-sm {
    min-height: 250px;
  }

  &-lg {
    min-height: 350px;
  }

  canvas {
    max-width: 100%;
  }

  .apexcharts-toolbar > div:not(.apexcharts-menu) {
    opacity: 0.5;
    transform: scale(1);
    transition: 200ms;
    margin-right: 5px;

    &:hover,
    &.apexcharts-selected {
      opacity: 1;
    }
  }
}

.legend {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  &__item {
    margin: 15px;
    padding: 10px;
    width: 150px;
    background-color: $blue-gray;
    color: #fff;
    border-radius: 5px;
    border: 0;
    box-sizing: border-box;
    transition: 200ms;
    flex: 0 0 150px;

    &:hover {
      box-shadow: 0 4px 12px rgba($pulsate-blue, 0.4);
    }

    &:focus {
      outline: 0;
    }

    &--active {
      background-color: #fff;
      box-shadow: 0 2px 6px rgba($pulsate-blue, 0.4);
      color: $body-color;
    }
  }

  &__value {
    font-size: 24px;
  }
}

.table-name {
  color: $blue-gray !important;
  font-size: 14px !important;

  &:before {
    margin-right: 12px;
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid $blue-gray;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    line-height: 36px;
  }
}

.percentage-rate {
  position: relative;
  color: $blue-gray;

  &--green {
    color: $info;
  }
  &--green &__arrow {
    margin: 0 2px;
    display: inline-block;
    border-bottom: 9px solid $info;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  &--red {
    color: $danger;
  }

  &--red &__arrow {
    margin: 0 2px;
    display: inline-block;
    border-top: 9px solid $danger;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}
