@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #eJdNwaOsyZc1 {
    pointer-events: all
  }

  #eJdNwaOsyZc15_to {
    animation: eJdNwaOsyZc15_to__to 2000ms linear infinite normal forwards
  }


  #eJdNwaOsyZc26 {
    animation: eJdNwaOsyZc26_c_o 2000ms linear infinite normal forwards
  }


  #eJdNwaOsyZc15_tr {
    animation: eJdNwaOsyZc15_tr__tr 2000ms linear infinite normal forwards
  }


  #eJdNwaOsyZc15 {
    animation: eJdNwaOsyZc15_c_o 2000ms linear infinite normal forwards
  }


  #eJdNwaOsyZc23_to {
    animation: eJdNwaOsyZc23_to__to 2000ms linear infinite normal forwards
  }


  #eJdNwaOsyZc26_to {
    animation: eJdNwaOsyZc26_to__to 2000ms linear infinite normal forwards
  }

  #eJdNwaOsyZc25_to {
    animation: eJdNwaOsyZc25_to__to 2000ms linear infinite normal forwards
  }


  #eJdNwaOsyZc24_to {
    animation: eJdNwaOsyZc24_to__to 2000ms linear infinite normal forwards
  }
}

@keyframes :global(eJdNwaOsyZc15_to__to) {
  0% {
    transform: translate(174px, 79px)
  }
  30% {
    transform: translate(174px, 79px)
  }
  50% {
    transform: translate(174px, 56.89px)
  }
  100% {
    transform: translate(174px, 56.89px)
  }
}


@keyframes :global(eJdNwaOsyZc15_tr__tr) {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(0deg)
  }
  60% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(180deg)
  }
}


@keyframes :global(eJdNwaOsyZc15_c_o) {
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}


@keyframes :global(eJdNwaOsyZc23_to__to) {
  0% {
    transform: translate(236.616323px, 23.616323px)
  }
  15% {
    transform: translate(56.62px, 23.616323px)
  }
  100% {
    transform: translate(56.62px, 23.616323px)
  }
}


@keyframes :global(eJdNwaOsyZc24_to__to) {
  0% {
    transform: translate(306px, 23.616323px)
  }
  25% {
    transform: translate(126px, 23.616323px)
  }
  100% {
    transform: translate(126px, 23.616323px)
  }
}



@keyframes :global(eJdNwaOsyZc25_to__to) {
  0% {
    transform: translate(359px, 24px)
  }
  35% {
    transform: translate(179px, 24px)
  }
  100% {
    transform: translate(179px, 24px)
  }
}


@keyframes :global(eJdNwaOsyZc26_to__to) {
  0% {
    transform: translate(103.602px, 276.5px)
  }
  30% {
    transform: translate(103.602px, 136.5px)
  }
  100% {
    transform: translate(103.602px, 136.5px)
  }
}


@keyframes :global(eJdNwaOsyZc26_c_o) {
  0% {
    opacity: 0
  }
  30% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}