@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .journey-email-template-builder {
    height: 100%;

    .unlayer-editor {
      &__header {
        padding: 0 70px 20px 50px;
      }
    }
  }
}