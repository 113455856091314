@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.goal-checkbox {
  display: none;

  &__label {
    position: relative;
    display: block;
    padding: 5px 0;
    min-height: 32px;
    min-width: 32px;
    width: fit-content;

    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      display: inline-block;
      width: 32px;
      height: 32px;
      border: 2px solid $blue-gray;
      border-radius: 50%;
      background-color: white;
      outline: none;
      transition-duration: 0.3s;
      transform: translate(0, -50%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 7px;
      display: block;
      width: 18px;
      height: 18px;
      background-image: url(../../../../../components/icons/cup.svg);
    }
  }
}

.goal-checkbox:checked + .goal-checkbox__label--primary {
  &::before {
    border-color: $yellow;
    background-color: $yellow;
  }
}

.goal-checkbox:checked + .goal-checkbox__label--secondary {
  &::before {
    border-color: $silver-gray;
    background-color: $silver-gray;
  }
}

// .goal-checkbox:disabled + .goal-checkbox-container:before {
//   border: 1px solid $blue-gray;
//   background-color: $blue-gray;
// }

.label {
  display: block;
  padding-left: 40px;
}

.goal-icon {
  display: inline-block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: url(../../../../../components/icons/cup.svg) center / 10px 10px
    no-repeat;
  vertical-align: text-top;

  &--primary {
    background-color: $yellow;
  }

  &--secondary {
    background-color: $silver-gray;
  }

  &--external {
    background-color: $fuchsia-pink;
  }

  &--lg {
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
  }

  &--xl {
    width: 42px;
    height: 42px;
    background-size: 23px 23px;
  }
}

.goal-list {
  padding: 30px;
  border: 1px dashed $bali-hai-blue;
  border-radius: 3px;
}

.goal-return-icon {
  display: inline-block;
  height: 76px;
  width: 76px;
  background: url(../../../../../components/icons/return.svg) center / contain
    no-repeat;
}

.goal-placeholder {
  margin: 0 5px;
  width: 24px;
  height: 24px;
  display: inline-block;
  border: 2px solid $blue-gray;
  border-radius: 50%;

  &--no-border {
    border: 0;
    background: rgba(#b3c2d6, 0.6);
  }
}
