@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .icon-button {
    width: 40px;
    height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 100% !important;

    @include media-breakpoint-up('hd') {
      font-size: 20px !important;
    }

    &:hover {
      background-color: rgba($body-color, 0.3);
    }

    svg {
      padding: 0;
    }
  }
}
