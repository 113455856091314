@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  #inAppAnimatedIcon {
    pointer-events: all
  }

  #animatedInAppIcon1_to {
    animation: animatedInAppIcon1_to__to 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon1 {
    animation: animatedInAppIcon1_c_o 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon2_to {
    animation: animatedInAppIcon2_to__to 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon2 {
    animation: animatedInAppIcon2_c_o 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon2_ts {
    animation: animatedInAppIcon2_ts__ts 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon3 {
    animation: animatedInAppIcon3_c_o 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon7 {
    animation: animatedInAppIcon7_c_o 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon5 {
    animation: animatedInAppIcon5_c_o 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon4 {
    animation: animatedInAppIcon4_c_o 2000ms linear infinite normal forwards
  }

  #animatedInAppIcon4_to {
    animation: animatedInAppIcon4_to__to 2000ms linear infinite normal forwards
  }
}

@keyframes :global(animatedInAppIcon1_to__to) {
  0% {
    transform: translate(90px, 26.5px)
  }
  15% {
    transform: translate(50px, 26.5px)
  }
  100% {
    transform: translate(50px, 26.5px)
  }
}

@keyframes :global(animatedInAppIcon1_c_o) {
  0% {
    opacity: 0
  }
  15% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animatedInAppIcon2_to__to) {
  0% {
    transform: translate(36px, 103.5px)
  }
  25% {
    transform: translate(36px, 84.5px)
  }
  100% {
    transform: translate(36px, 84.5px)
  }
}

@keyframes :global(animatedInAppIcon2_ts__ts) {
  0% {
    transform: scale(0.6, 0.6)
  }
  25% {
    transform: scale(1, 1)
  }
  100% {
    transform: scale(1, 1)
  }
}

@keyframes :global(animatedInAppIcon2_c_o) {
  0% {
    opacity: 0
  }
  25% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animatedInAppIcon3_c_o) {
  0% {
    opacity: 1
  }
  40% {
    opacity: 1
  }
  50% {
    opacity: 0.2
  }
  60% {
    opacity: 1
  }
  70% {
    opacity: 0.2
  }
  80% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animatedInAppIcon4_to__to) {
  0% {
    transform: translate(-5px, 27px)
  }
  15% {
    transform: translate(16px, 27px)
  }
  100% {
    transform: translate(16px, 27px)
  }
}

@keyframes :global(animatedInAppIcon4_c_o) {
  0% {
    opacity: 0
  }
  15% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animatedInAppIcon5_c_o) {
  0% {
    opacity: 0
  }
  30% {
    opacity: 0
  }
  40% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}

@keyframes :global(animatedInAppIcon7_c_o) {
  0% {
    opacity: 0
  }
  20% {
    opacity: 0
  }
  30% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
}