@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.logo {
  min-height: 80px;
}

.logo-bottom-shadow {
  padding-top: 13px;
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba($pulsate-blue, 0.1) 100%
  );
  transform: rotate(-180deg);
}

.border-wrapper {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #c04db9, #623eb2);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.sidebar-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  line-height: 27px;

  img {
    display: inline-block;
    object-fit: cover;
    margin-bottom: 0 !important;
  }

  div {
    min-height: 1rem;
  }

  small {
    display: inline-block;
    min-height: 1rem;
  }
}

.navbar-height {
  height: 100vh;
}

.footer {
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: rgba($dark, 0.5);
  margin-bottom: 20px;
}

.sidebar-item::before {
  content: '- ';
}

.simple-category {
  &:hover {
    text-decoration: none;
  }
}

.width-placeholder {
  min-width: 250px;
  transition: all 0.2s ease-out;

  &.closed {
    width: 0;
  }
}

.badge {
  font-size: 13px;
  border-radius: 3px !important;
  padding: 4px 7px;
}

:global {
  .sidebar-link:hover {
    color: #c050ba !important;
  }

  .sidebar-item.active > .sidebar-link,
  .sidebar-item.active > a > .sidebar-link {
    background-color: #e9ecef;
  }

  .sidebar-item.active > .sidebar-link.active {
    font-weight: bold;
  }

  .sidebar-item-category:has(.sidebar-link.active) > .sidebar-link {
    background-color: #e9ecef;
  }
}
