@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.stats {
  display: inline-grid;
  border-radius: 16px;
  background-color: white;
  color: black;
  grid-auto-flow: column;
  width: 100%;
  height: 100%;
  margin-bottom: 0 !important;

  > :not([hidden]) ~ :not([hidden]) {
    border-right-width: 0;
    border-left-width: 5px;
  }

  &--vertical {
    grid-auto-flow: row;
    //height: calc(100% - 25px);

    > :not([hidden]) ~ :not([hidden]) {
      border-right-width: 0;
      border-left-width: 0;
      border-top-width: 5px;
      border-bottom-width: 0px;
    }
  }
}

.stat-title {
  font-size: 18px;
  margin-bottom: 0;

  @include media-breakpoint-between('xxl', 'xxxl') {
    font-size: 16px;
  }
}

.stat {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: rgba(#EEF2F780, 0.5);
  display: inline-grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1rem;
  padding: 20px 23px 16px 23px;
}

.chart {
  height: 170px;

  @include media-breakpoint-up('xxxl') {
    height: 160px;
  }
}