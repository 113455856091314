@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.form-wrapper {
  overflow-x: hidden;
  width: 350px;
  display: flex;
  transition: height 0.3s;
  overflow-y: hidden;
  padding: 0 3px 0 3px;

  &--step-1-active {
    height: 490px;
  }

  &--step-2-active {
    height: 378px;
  }
}

.step {
  flex: 0 0 343px;
  transition: .4s;
  opacity: 0;

  &--active {
    opacity: 1;
  }
}