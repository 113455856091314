@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
@import 'mathsass/dist/math';
///https://codepen.io/lbebber/pen/RNgBPP
//vars
$pi: 3.14;

//config
$menu-items: 5 + 1;
$open-distance: 45px;
$opening-angle: $pi * 2;

.edge-foreignobject {
  position: absolute;
  pointer-events: all;
  overflow: visible;
  z-index: 10;
}

.shadow {
  display: none;
}

%goo {
  filter: url('#shadowed-goo');
  // debug
  // background:rgba(255,0,0,0.2);
}

%ball {
  background: $pulsate-blue;
  width: 35px;
  height: 35px;
  display: block;
  color: white;
  text-align: center;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  border-radius: 100%;
  cursor: pointer;
}

.add-new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.menu {
  position: relative;
  //! width of parent box has to be equal space that children take, other than that children will blink
  width: 100%;

  &__open {
    display: none;
  }

  &__open-btn {
    @extend %ball;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 400ms;
    transform: scale(1.1) translateX(0);

    &:hover {
      transition-duration: 400ms;
      transform: scale(1.5) translateX(0);
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 50%;
      height: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      transition: 200ms;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      height: 50%;
      width: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      transition: 200ms;
    }
  }

  &__item {
    @extend %ball;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;

    &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
    }

    @for $i from 1 through $menu-items {
      &:nth-child(#{$i + 2}) {
        transition-duration: 180ms;
      }
    }

    &--disabled {
      background-color: $blue-gray;
    }
  }
}

.menu__open:checked + * > .menu__open-btn {
  transition-timing-function: linear;
  transition-duration: 100ms;
  transform: scale(0.8) rotate(45deg) translate3d(0, 0, 0);
}

.menu__open:checked ~ .menu__item {
  @extend %goo;
  width: 35px;
  height: 35px;
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);

  &:hover {
    transition-duration: 400ms;
    transform: scale(1.5) translateX(0);
  }

  @for $i from 1 through $menu-items {
    $angle: (($pi - $opening-angle)/2)+
      (($opening-angle/($menu-items - 1)) * ($i - 1));

    &:nth-child(#{$i + 2}) {
      transition-duration: 80ms+ (100ms * $i);
      transform: translate3d(
        cos($angle) * $open-distance,
        sin($angle) * $open-distance,
        0
      );
    }
  }
}
