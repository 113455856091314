@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.column {
  min-width: 300px;
  max-width: 430px;
  color: #3e4676;
  padding: 15px 0 15px 25px !important;
}

.actions-column {
  text-align: right;
  color: #3e4676;
  padding: 5px 15px 15px 25px !important;
}