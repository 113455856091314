// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1352px,
  xxxl: 1600px,
  hd: 1800px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
  xxxl: 1640px
) !default;

// Default text color
$body-color: $pulsate-blue;

// Default border-radius
$border-radius: 3px;

$border-color: $border;

// Default outer background color
$wrapper-bg: $link-water-gray;

// Header color
$header-color: $dark;
$headings-color: $dark;

// Text
$font-size-base: 0.9375rem;
$h2-font-size: calc(1.5rem + 2px);
$h3-font-size: calc(1.25rem + 2px);
$h4-font-size: calc(1rem + 2px);
$h5-font-size: calc(0.75rem + 2px);
$headings-font-family: 'Poppins', -apple-system, blinkmacsystemfont, 'Segoe UI',
  'Helvetica Neue', arial, sans-serif;
$text-muted: $blue-gray;

// Make buttons taller by default
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem !default;
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-padding-y-lg: 0.7rem !default;
$btn-padding-x-lg: 1.4rem !default;
$btn-font-size-lg: $font-size-base !default;
$btn-border-radius-lg: 3px;

// Default box shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
$btn-box-shadow: 0 4px 4px $bali-hai;
$box-shadow-md:  0 4px 8px rgba(51, 51, 51, 0.15);


// Dropdowns
$dropdown-bg: $link-water-gray;
$dropdown-border-width: 1px;
$dropdown-border-color: rgba($pulsate-blue, 0.5);
$dropdown-link-hover-bg: $light;
$dropdown-item-padding-x: 15px;
$dropdown-link-color: $body-color;
$dropdown-item-padding-y: 0.3rem;

// Modal
$modal-backdrop-bg: $body-color;
$modal-backdrop-opacity: 0.85;
$modal-sm: 400px;
$modal-md: 600px;
$modal-lg: 900px;
$modal-xl: 1140px;

// Sidebar
$sidebar-link-color: $body-color;
$sidebar-link-active-color: $sidebar-link-color;
$sidebar-link-child-color: $sidebar-link-color;
$sidebar-link-child-active-color: $sidebar-link-color;

// Forms
$input-placeholder-color: rgba($pulsate-blue, 0.5);
$input-placeholder-font-weight: 400;
$input-border-color: $bali-hai-blue !default;
$input-color: $dark !default;
$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-group-addon-padding-y: 0;
$input-group-addon-bg: white;

$custom-range-thumb-bg: $secondary;
$custom-range-thumb-active-bg: $secondary;

//Tooltips
$tooltip-border-radius: $border-radius !default;

// Accordion
$accordion-bg: #fff;
$accordion-color: $body-color;
$accordion-border-width: 0px;
$accordion-button-active-bg: #fff;
$accordion-button-active-color: $body-color;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath fill='%2328518c' d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' %3E%3C/path%3E%3C/svg%3E");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath fill='%2328518c' d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' %3E%3C/path%3E%3C/svg%3E");
$accordion-icon-transform: rotate(180deg);
$accordion-button-focus-box-shadow: none;
$accordion-button-padding-x: 16px;
$accordion-body-padding-x: 16px;

//Table
$table-striped-order: even !default;
// $table-accent-bg: $link-water-gray !default;
$btn-disabled-border-color: transparent;

$form-label-font-size: 14px;

$enable-negative-margins: true;