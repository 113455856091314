@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
  .engagement-chart {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 10px;
  }

  .label-text {
    font-weight: 500;
    color: #3d3d3d;
  }

  .progress-bar {
    font-weight: bold;
    font-size: 18px;
    color: $body-color;
    display: flex;
    align-items: center;
    width: 228.26px;
    max-width: 100%;
    height: 60px;
    border-radius: 2px 40px 40px 2px;
    padding-left: 25px;
    padding-right: 25px;
  }
