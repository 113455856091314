@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .send-campaign-modal {
    &__box {
      margin: 0 20%;
    }
    &__value {
      font-size: 16px;
      margin-bottom: 5px;

      &--app {
        margin-bottom: 0px;
      }
    }

    &__value-description {
      margin-bottom: 0;
      color: $blue-gray;
      font-size: 13px;
    }

    &__icon {
      width: 38px;
      &--rounded {
        border-radius: 50%;
      }
    }
  }
}
