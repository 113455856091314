@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .export-by-date {
    .dropdown-menu {
      overflow: inherit !important;
    }

    &__form {
      min-width: 220px;

      .react-datepicker-wrapper {
        .form-control {
          width: 149px;
        }
      }
    }
  }
}