@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
.card {
  width: 800px;
  height: 187px;
  padding-top: 23px;
  padding-bottom: 23px;
  display: flex;
  justify-content: center;
  background-color: $alabaster;
  border: 1px solid $bali-hai-blue;
  border-radius: 3px;

  > * + * {
    border-left: 1px solid $mystic-gray;
  }
}

.goal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  header {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.control-group {
  width: 800px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: white;
  background-color: $blue-gray;
  border-radius: 3px;
  margin: 14px auto 0 auto;

  .title {
    font-weight: 500;
  }

  strong {
    font-weight: 700;
  }
}

:global {
  .goals-divider {
    width: 0.5px;
    height: 100px;
    background-color: $mystic-gray
  }
}
