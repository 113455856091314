@import "~/assets/css/colors";
                               @import "~/assets/css/_theme_customizations.scss";
                               @import "bootstrap/scss/mixins/breakpoints";
:global {
  .file-upload {
    width: 100%;

    &__dropzone,
    &__preview {
      max-width: 450px;
    }

    &__dropzone {
      position: relative;
      border: 2px dotted lightgray;
      padding: 65px 20px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: $link-water-gray;
    }

    &__preview {
      margin: 0 auto;
    }

    &__input {
      cursor: pointer;
      display: block;
      width: 100%;
      border: none;
      text-transform: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      font-size: 0;

      &:focus {
        outline: none;
      }
    }

    &__file-name {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__retry-btn {
      color: $danger;
      padding-left: 5px;
      padding-right: 0px;

      &:hover {
        color: $danger;
      }
    }

    &__preview-image {
      width: 70px;
      height: 70px;
      background-size: cover;
      background-position: center;
    }
  }
}